export enum ENTITLEMENTS {
  PRO = 'PRO',
  PLUS = 'PLUS',
  LITE = 'LITE',
  FEATURE_TASKS = 'FEATURE_TASKS',
  FEATURE_CUSTOM_MARKS = 'FEATURE_CUSTOM_MARKS',
  FEATURE_BOOKINGS = 'FEATURE_BOOKINGS',
  FEATURE_HYBRID_MAP = 'FEATURE_HYBRID_MAP',
  FEATURE_COUNTRY_PARCELS = 'FEATURE_COUNTRY_PARCELS',
  FEATURE_EXTENDED_DISTRICT_FILTER = 'FEATURE_EXTENDED_DISTRICT_FILTER',
  FEATURE_DISTANCE_RINGS = 'FEATURE_DISTANCE_RINGS',
  FEATURE_USER_COMPASS = 'FEATURE_USER_COMPASS',
  FEATURE_MULTIPLE_IMAGES_UPLOAD = 'FEATURE_MULTIPLE_IMAGES_UPLOAD',
  FEATURE_MULTIPLE_IMAGES_VIEW = 'FEATURE_MULTIPLE_IMAGES_VIEW',
  FEATURE_HARVEST_TAG_EXPORT = 'FEATURE_HARVEST_TAG_EXPORT',
  FEATURE_CHECKIN_TAG_EXPORT = 'FEATURE_CHECKIN_TAG_EXPORT',
  FEATURE_WEATHER = 'FEATURE_WEATHER',
  FEATURE_MAP_PATH = 'FEATURE_MAP_PATH',
  FEATURE_SCENT_CLOUD = 'FEATURE_SCENT_CLOUD',
  FEATURE_SUBZONE = 'FEATURE_SUBZONE',
  FEATURE_SHOOTING_DIRECTION = 'FEATURE_SHOOTING_DIRECTION',
  FEATURE_CHECKIN_POI = 'FEATURE_CHECKIN_POI',
  FEATURE_HUNTING_SEASON = 'FEATURE_HUNTING_SEASON',
  FEATURE_EVENT = 'FEATURE_EVENT',
  FEATURE_CHAT = 'FEATURE_CHAT',
  FEATURE_WATER_SOURCE = 'FEATURE_WATER_SOURCE',
  FEATURE_CUSTOM_UNLIMTED_AREAS = 'FEATURE_CUSTOM_UNLIMTED_AREAS',
  FEATURE_DOCUMENTS = 'FEATURE_DOCUMENTS',
  FEATURE_OFFLINE_MAP = 'FEATURE_OFFLINE_MAP',
  FEATURE_UNLIMITED_POIS = 'FEATURE_UNLIMITED_POIS',
  FEATURE_UNLIMITED_ENTRIES = 'FEATURE_UNLIMITED_ENTRIES',
  FEATURE_QUOTA_PLANS = 'FEATURE_QUOTA_PLANS',
  FEATURE_DOG_TRACKING = 'FEATURE_DOG_TRACKING'
}

export enum ENTITLEMENTS_LIMIT {
  NUMBER_OF_FREE_DISTRICTS = 1,
  NUMBER_OF_FREE_POIS = 5,
  NUMBER_OF_FREE_ENTRIES = 5
}
