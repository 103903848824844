import { inject } from 'inversify';
import { action, makeObservable, observable } from 'mobx';

import { AsyncTask } from '../../../../../../domain/async/AsyncTask';
import { ViewModel } from '../../../../../../domain/core/ViewModel';
import { PoiProxy } from '../../../../../../domain/proxy/PoiProxy';
import { NotificationService } from '../../../../../../domain/service/NotificationService';
import { SessionStore } from '../../../../../../domain/store/SessionStore';
import { transient } from '../../../../../../inversify/decorator';
import { CAMERA_MANUFACTURER_ENUM } from '../../../../../../toolkit/enums/cameraManufacturerEnum';
import { IViewConnectCameraProps } from './ViewConnectCamera';

export enum ViewConnectCameraStep {
  NOT_CONNECTED_CAM = 'NOT_CONNECTED_CAM',
  CONNECTED_CAM = 'CONNECTED_CAM',
}

@transient()
export class ViewConnectCameraVm extends ViewModel<IViewConnectCameraProps> {

  @observable
  public step: ViewConnectCameraStep = ViewConnectCameraStep.NOT_CONNECTED_CAM;

  @observable
  public cameraManufacturer: CAMERA_MANUFACTURER_ENUM = CAMERA_MANUFACTURER_ENUM.SEISSIGER;

  constructor(
    @inject(PoiProxy) public readonly poiProxy: PoiProxy,
    @inject(SessionStore) public readonly session: SessionStore,
    @inject(NotificationService) private readonly notification: NotificationService,
  ) {
    super();
    makeObservable(this);
  }

  @action
  public setStep = (step: ViewConnectCameraStep) => {
    this.step = step;
  }

  @action
  public setCameraManufacturer = (camera: CAMERA_MANUFACTURER_ENUM) => {
    this.cameraManufacturer = camera;
  }

  public removeConnection = new AsyncTask(async () => {
    try {
      const response = await this.poiProxy.removeTrailCamConnection(this.props.poi.id);

      if (response.ok) {
        this.notification.success('poi:trail_camera.connect_camera.remove_connection.success');
        this.props.onRemoveConnection(this.props.poi.id);
      }
    } catch (error) {
      console.error('Something went wrong while trying to remove trail cam connection');
      this.notification.error('poi:trail_camera.connect_camera.remove_connection.error');
    }
  })

}
