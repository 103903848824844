/* eslint-disable @typescript-eslint/no-explicit-any */

/**
 * Helper to fetch a file from a URL and trigger its download.
 * Automatically extracts the filename from the Content-Disposition header, if available.
 */
export class FileDownloadHelper {
  /**
   * Fetches a file from the provided URL and triggers a download.
   * @param url - The URL to fetch the file from.
   * @param token - Optional authorization token for the request.
   * @param fallbackFilename - Optional fallback filename if none is provided by the server.
   */
  static async fetchAndDownloadFile(url: string, token?: string, fallbackFilename: string = 'download') {
    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: token
          ? {
            Authorization: `Bearer ${token}`,
          }
          : undefined,
      });

      if (!response.ok) {
        throw new Error('Failed to fetch the file from the server');
      }

      // Extract the filename from Content-Disposition
      const contentDisposition = response.headers.get('Content-Disposition');
      const filename = contentDisposition
        ? FileDownloadHelper.extractFileName(contentDisposition)
        : fallbackFilename;

      // Convert the response to a Blob
      const blob = await response.blob();

      // Force "Save As" dialog
      await FileDownloadHelper.triggerSaveAsDialog(blob, filename);
    } catch (error) {
      console.error('Error during file download:', error);
      throw error;
    }
  }

  /**
   * Extracts the filename from the Content-Disposition header.
   * @param contentDisposition - The Content-Disposition header value.
   * @returns The extracted filename or a fallback.
   */
  private static extractFileName(contentDisposition: string): string {
    const fileNameMatch = contentDisposition.match(/filename="?(.+)"?/);
    return fileNameMatch?.[1].trim() || 'download.xlsx';
  }

  /**
   * Triggers the download of a Blob with the specified filename.
   * @param blob - The Blob object representing the file.
   * @param filename - The name for the downloaded file.
   */
  private static async triggerSaveAsDialog(blob: Blob, filename: string) {
    // Clean up the filename if it contains unexpected suffixes after ".xlsx"
    const cleanedFilename = filename.replace(/(\.xlsx).*$/, '$1');

    if ((window as any).showSaveFilePicker) {
      // Use File System Access API if available
      const fileHandle = await (window as any).showSaveFilePicker({
        suggestedName: cleanedFilename,
        types: [
          {
            description: 'Excel Files',
            accept: { 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'] },
          },
        ],
      });

      const writable = await fileHandle.createWritable();
      await writable.write(blob);
      await writable.close();
    } else {
      // Fallback to <a> download
      const link = document.createElement('a');
      const url = URL.createObjectURL(blob);
      link.href = url;
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url); // Clean up memory
    }
  }
}
