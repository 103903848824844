import { inject } from 'inversify';
import { action, computed, makeObservable, observable } from 'mobx';

import { ViewModel } from '../../../../../../../domain/core/ViewModel';
import { PoiModel } from '../../../../../../../domain/model/PoiModel';
import { I18nService } from '../../../../../../../domain/service/I18nService';
import { SessionStore } from '../../../../../../../domain/store/SessionStore';
import { transient } from '../../../../../../../inversify/decorator';
import { POI_TYPE } from '../../../../../../../shared/enum';
import { IPoiTab } from '../../../../poi/new-poi/NewPoiVm';

export interface IViewDistrictPoisProps {
  pois: PoiModel[];
  onEdit: (poi: PoiModel, tab: IPoiTab) => void;
  onViewDetails: (poi: PoiModel) => void;
  onDelete: (poi: PoiModel) => void;
  canEditAndDeletePois: boolean;
}

type TabKey = POI_TYPE | 'all';

interface IViewDistrictPoiTab {
  key: TabKey;
  count: number;
  text: string;
}

@transient()
export class ViewDistrictPoisVm extends ViewModel<IViewDistrictPoisProps> {

  @observable
  public activeTab: TabKey = 'all';

  constructor(
    @inject(I18nService) private readonly i18n: I18nService,
    @inject(SessionStore) private readonly session: SessionStore,
  ) {
    super();
    makeObservable(this);
  }

  @computed
  public get tabs(): IViewDistrictPoiTab[] {
    const tabs: { [key: string]: IViewDistrictPoiTab } = {};

    tabs['all'] = {
      key: 'all',
      count: this.props.pois.length,
      text: this.i18n.t('district:district_details_show_all_items'),
    };

    this.props.pois.forEach((p) => {
      if (!p.type) {
        return;
      }

      tabs[p.type] = tabs[p.type] || { key: p.type, count: 0, text: this.i18n.t(p.typeI18nKey) };
      tabs[p.type].count++;
    });

    /** Switch tabs if activeTab is poiType which doesn't exist anymore.  */
    if (!Object.keys(tabs).find(key => key === this.activeTab)) {
      this.changeTab(null, 'all');
    }

    return Object.values(tabs);
  }

  @computed
  public get pois(): PoiModel[] {
    return this.props.pois.filter((poi) => {
      if (this.activeTab === 'all') {
        return true;
      }

      return poi.type === this.activeTab;
    });
  }

  public canEditAndDeletePoi = (poi: PoiModel) => {
    if (poi.ownerId === this.session.currentUser?.id) {
      return true;
    }

    return this.props.canEditAndDeletePois;
  }

  @action
  public changeTab = (_: unknown, value: TabKey) => {
    this.activeTab = value;
  }
}
