import { action, computed, makeObservable, observable } from 'mobx';

import { Assets } from '../../assets';
import { PoiPostRequestDto, PoiPutRequestDto, PoiResponseDto } from '../../shared/dto';
import { POI_TYPE } from '../../shared/enum';
import { POI_CONNECTION_STATUS } from '../../shared/enum/poiConnection.enum';
import { ShootingAngleType, ShootingDirectionType } from '../../shared/types/shootingDirection';
import { poiUIModels } from '../../util/PoiUIModels';
import { IGeoLocation } from '../core/IGeoLocation';
import { BookingModel } from './BookingModel';
import { CustomMarkModel } from './CustomMarkModel';
import { DistrictModel } from './DistrictModel';
import { PositionSource } from './EntryModel';
import { PhotoModel } from './PhotoModel';
import { PoiCheckinHistoryModel } from './PoiCheckinHistoryModel';
import { PoiFeatureModel } from './PoiFeatureModel';
import { TaskModel } from './TaskModel';

const shootingDirectionPoiTypes = [POI_TYPE.BLIND, POI_TYPE.HIDEOUT, POI_TYPE.CAMERA];

export class PoiModel {

  @observable
  public id: string = '';

  @observable
  public type: POI_TYPE | undefined = undefined;

  @observable
  public lat: number | undefined = undefined;

  @observable
  public long: number | undefined = undefined;

  @observable
  public customMark: CustomMarkModel | undefined = undefined;

  @observable
  public booking: BookingModel | undefined = undefined;

  @observable
  public blocked: boolean = false;

  @observable
  public name: string = '';

  @observable
  public description: string = '';

  @observable
  public tasks: TaskModel[] = [];

  @observable
  public photos: PhotoModel[] = [];

  @observable
  public districtId: string | undefined = undefined;

  @observable
  public positionSource: PositionSource | null = null;

  @observable
  public features: PoiFeatureModel | null = null;

  @observable
  public ownerId: string = '';

  @observable
  public cameraEmail?: string | undefined = undefined;

  @observable
  public connectionStatus?: POI_CONNECTION_STATUS = POI_CONNECTION_STATUS.PENDING;

  @observable
  public shootingAngle?: ShootingAngleType = 0;

  @observable
  public shootingDirection?: ShootingDirectionType = 0;

  @observable
  public usersCheckedIn: PoiCheckinHistoryModel[] = [];

  constructor() {
    makeObservable(this);
  }

  @action
  public setShootingDirection = (direction: ShootingDirectionType) => {
    this.shootingDirection = direction;
  }

  @action
  public setShootingAngle = (angle: ShootingAngleType) => {
    this.shootingAngle = angle;
  }

  @computed
  public get directionLetter(): string {
    switch (this.shootingDirection) {
      case 0: return 'N';
      case 22.5: return 'NNE';
      case 45: return 'NE';
      case 67.5: return 'ENE';
      case 90: return 'E';
      case 112.5: return 'ESE';
      case 135: return 'SE';
      case 157.5: return 'SSE';
      case 180: return 'S';
      case 202.5: return 'SSW';
      case 225: return 'SW';
      case 247.5: return 'WSW';
      case 270: return 'W';
      case 292.5: return 'WNW';
      case 315: return 'NW';
      case 337.5: return 'NNW';
      default: '';
    }

    return '';
  }

  @computed
  public get isCustomPoi(): boolean {
    return this.type === POI_TYPE.CUSTOM;
  }

  @computed
  public get canHaveShootingDirection(): boolean {
    if (!this.type) {
      return false;
    }

    if (this.type === POI_TYPE.CUSTOM) {
      return this.features?.canHaveShootingDirection ||
        this.customMark?.features.canHaveShootingDirection ||
        false;
    }

    return shootingDirectionPoiTypes.includes(this.type);
  }

  @computed
  public get hasShootingDirection() {
    return !!this.shootingAngle && this.shootingAngle > 0;
  }

  @computed
  public get shootingAngleImage() {
    if (!this.shootingAngle) {
      return null;
    }

    return Assets.poi.shooting_direction[this.shootingAngle];
  }

  @computed
  public get location(): IGeoLocation | null {
    if (!this.lat || !this.long) {
      return null;
    }

    return {
      latitude: this.lat,
      longitude: this.long,
    };
  }

  @computed
  public get typeReady(): boolean {
    if (!this.type) {
      return false;
    }

    if (this.type !== POI_TYPE.CUSTOM) {
      return true;
    }

    return !!this.customMark?.background && !!this.customMark.foreground;
  }

  @action
  public setName = (name: string) => {
    this.name = name;
  }

  @action
  public setDescription = (description: string) => {
    this.description = description;
  }

  @action
  public setDistrictId = (districtId: string | undefined) => {
    this.districtId = districtId;
  }

  @action
  public setType = (type: POI_TYPE | undefined) => {
    this.type = type;

    if (!type) {
      this.customMark = undefined;
    }

    if (type === POI_TYPE.CUSTOM) {
      this.customMark = new CustomMarkModel();
    }
  }

  @action
  public setLocation = (location: IGeoLocation, source: PositionSource) => {
    this.lat = location.latitude;
    this.long = location.longitude;
    this.positionSource = source;
  }

  @action
  public setLatitude = (lat: number, source?: PositionSource) => {
    this.lat = lat;
    this.positionSource = source ?? null;
  }

  @action
  public setLongitude = (long: number, source?: PositionSource) => {
    this.long = long;
    this.positionSource = source ?? null;
  }

  @action
  public addPhoto = (photo: PhotoModel) => {
    this.photos.push(photo);
  }

  @computed
  public get typeI18nKey() {
    if (!this.type) {
      return '';
    }

    return poiUIModels[this.type]?.i18nKey;
  }

  @computed
  public get icon() {
    return PoiModel.typeToIcon(this.type);
  }

  public static typeToIcon(type: POI_TYPE | undefined | null): string {
    switch (type) {
      case POI_TYPE.BAIT_SEAT:
        return Assets.poi.baitsiteVege;
      case POI_TYPE.BAIT_SEAT_MEAT:
        return Assets.poi.baitsiteMeat;
      case POI_TYPE.CAMERA:
        return Assets.poi.camera;
      case POI_TYPE.CUSTOM:
        return Assets.poi.custom;
      case POI_TYPE.FEEDER:
        return Assets.poi.feedingsite;
      case POI_TYPE.HIDEOUT:
        return Assets.poi.highseat;
      case POI_TYPE.TRAP:
        return Assets.poi.trap;
      case POI_TYPE.BLIND:
        return Assets.poi.trapblind;
      case POI_TYPE.NEST:
        return Assets.poi.nest;
      case POI_TYPE.EARTHWORK:
        return Assets.poi.burrow;
      case POI_TYPE.WEATHER:
        return Assets.weather.poi_weather_background;
      case POI_TYPE.UNKNOWN:
        return Assets.poi.fallback;
      default:
        return Assets.poi.fallback;
    }
  }

  public static fromDto = (dto: PoiResponseDto) => {
    const model = new PoiModel();

    model.id = dto.id;
    model.name = dto.name;
    model.description = dto.description ?? '';
    model.type = dto.type as POI_TYPE;
    model.long = dto.lat; // switched because switched on server
    model.lat = dto.long; // switched because switched on server
    model.customMark = dto.customMark ? CustomMarkModel.fromDto(dto.customMark) : undefined;
    model.booking = dto.booking ? BookingModel.fromDto(dto.booking) : undefined;
    model.blocked = dto.blocked;
    model.photos = dto.photos?.map((photo) => PhotoModel.fromDto(photo, dto.photo)) ?? [];
    model.tasks = dto.tasks?.map(TaskModel.fromDto) ?? [];
    model.districtId = dto.districtId ? dto.districtId : DistrictModel.worldMapId;
    model.features = dto.features ? PoiFeatureModel.fromDto(dto.features) : null;
    model.shootingDirection = dto.shootingDirection ?? 0;
    model.shootingAngle = dto.shootingAngle ?? 0;
    model.ownerId = dto.ownerId;
    model.cameraEmail = dto.cameraEmail;
    model.connectionStatus = dto.connectionStatus;
    model.usersCheckedIn = dto.usersCheckedIn ? dto.usersCheckedIn.map(user => PoiCheckinHistoryModel.fromDto(user)) : [];

    return model;
  }

  public clone = () => {
    const cloned = new PoiModel();

    cloned.id = this.id;
    cloned.type = this.type;
    cloned.lat = this.lat;
    cloned.long = this.long;
    cloned.customMark = this.customMark?.clone();
    cloned.booking = this.booking?.clone();
    cloned.blocked = this.blocked;
    cloned.name = this.name;
    cloned.description = this.description;
    cloned.photos = this.photos;
    cloned.tasks = this.tasks;
    cloned.districtId = this.districtId;
    cloned.features = this.features?.clone() ?? null;
    cloned.shootingDirection = this.shootingDirection ?? 0;
    cloned.shootingAngle = this.shootingAngle ?? 0;
    cloned.ownerId = this.ownerId;
    cloned.cameraEmail = this.cameraEmail;
    cloned.connectionStatus = this.connectionStatus;

    return cloned;
  }

  public toPutDto = (): PoiPutRequestDto => {
    const dto: PoiPutRequestDto = {
      id: this.id,
      districtId: this.districtId !== DistrictModel.worldMapId ? this.districtId : undefined,
      name: this.name,
      description: this.description,
      type: this.type,
      lat: this.long!, // switched because switched on server
      long: this.lat!, // switched because switched on server
      customMark: this.customMark ? CustomMarkModel.toPostDto(this.customMark) : undefined,
      shootingAngle: this.shootingAngle,
      shootingDirection: this.shootingDirection,
    };

    return dto;
  }

  public toPostDto = (): PoiPostRequestDto => {
    const dto: PoiPostRequestDto = {
      districtId: this.districtId !== DistrictModel.worldMapId ? this.districtId : undefined,
      name: this.name,
      description: this.description,
      type: this.type ? this.type as POI_TYPE : POI_TYPE.UNKNOWN,
      lat: this.long!, // switched because switched on server
      long: this.lat!, // switched because switched on server
      customMark: this.customMark ? CustomMarkModel.toPostDto(this.customMark) : undefined,
      shootingAngle: this.shootingAngle,
      shootingDirection: this.shootingDirection,
    };

    return dto;
  }
}
