import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Divider, Grid, ThemeProvider, Typography } from '@mui/material';
import { Box } from '@mui/system';

import { PoiModel } from '../../../../../../domain/model/PoiModel';
import { POI_TYPE } from '../../../../../../shared/enum';
import { useThemeContext } from '../../../../../../theme/ThemeContextProvider';

interface IProps {
  poi: PoiModel;
}

export const PoiNotes = observer(function PoiNotes({ poi }: IProps) {
  const { t } = useTranslation();
  const { theme } = useThemeContext();

  const trailCamAddition = useMemo(() => {
    return (
      <ThemeProvider theme={theme}>
        <Grid container direction={'column'} rowGap={2} paddingY={4} paddingX={2}>
          <Grid item>
            <Typography variant='text_sm_semibold' color={theme.palette.text.primary}>
              {t('poi:trail_camera.info.heading')}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant='text_sm_regular' color={theme.palette.text.primary}>
              {t('poi:trail_camera.info.body_1')}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant='text_sm_regular' color={theme.palette.text.primary}>
              {t('poi:trail_camera.info.body_2')}
            </Typography>
          </Grid>
        </Grid>
      </ThemeProvider>
    );
  }, [t, theme]);

  return (
    <Box width={'100%'}>
      <Box paddingY={2} paddingX={2}>
        <textarea
          placeholder={t('poi:new_poi_description_placeholder')}
          rows={5}
          style={{
            width: '100%',
            borderStyle: 'none',
            outline: 'none',
            fontSize: '15px',
            fontWeight: '600',
            color: '#545B68',
          }}
          value={poi.description}
          onChange={(e) => poi.setDescription(e.target.value)}
        />
      </Box>
      <Divider />
      {poi.type === POI_TYPE.CAMERA && trailCamAddition}
    </Box>
  );
});
