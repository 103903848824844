import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';

import { Box, ThemeProvider } from '@mui/material';

import { Assets } from '../../../../../../assets';
import { useInstance } from '../../../../../../domain/hooks/useInstance';
import { useVm } from '../../../../../../domain/hooks/useVm';
import { SessionStore } from '../../../../../../domain/store/SessionStore';
import { FEATURE } from '../../../../../../shared/enum';
import { useThemeContext } from '../../../../../../theme/ThemeContextProvider';
import { InfoComponent } from '../../../../../../toolkit/components/InfoComponent';
import { ProCtaButton } from '../../../../../../toolkit/components/ProCtaButton';
import {
  NewSubmitButton
} from '../../../../../../toolkit/components/redesign-components/NewSubmitButton';
import { TaskList } from './task-list/TaskList';
import { Task } from './task/Task';
import { TasksScreens, ViewTasksProps, ViewTasksVm } from './ViewTasksVm';

export const ViewTasks = observer(function ViewTasks(props: ViewTasksProps) {
  const session = useInstance(SessionStore);
  const vm = useVm(ViewTasksVm, props);
  const { theme } = useThemeContext();

  useEffect(() => {
    vm.setStep(TasksScreens.TASKS_LIST);
  }, [props.poi, props.tasks, vm]);

  const renderTasksList = () => (
    <Box display="flex" flexDirection="column" flex="1 0 auto">
      <Box marginX={4}>
        <TaskList
          poi={props.poi}
          pois={props.pois}
          tasks={props.tasks}
          district={props.district}
          onEditTask={vm.setTask}
          onDeleteTask={vm.deleteTask.run}
          markAsCompleted={vm.toggleTaskDone.run}
          onTabChange={vm.setActiveTab}
        />
      </Box>
      <ThemeProvider theme={theme}>
        <Box
          marginTop="auto"
          position="sticky"
          bgcolor="white"
          borderTop={1}
          bottom={0}
          display="flex"
          justifyContent="space-around"
          alignItems="center"
          minHeight="70px"
          paddingX={3}
          gap={1}
          zIndex={2}
        >
          <NewSubmitButton
            color="secondary"
            sx={{ width: '170px' }}
            onClick={vm.exportTasks.run}
          >
            {t('task:btn.export_tasks')}
          </NewSubmitButton>
          <NewSubmitButton
            color="primary"
            sx={{ width: '170px' }}
            onClick={() => vm.setTask(null)}
          >
            {t('task:btn.new_task')}
          </NewSubmitButton>
        </Box>
      </ThemeProvider>
    </Box>
  );

  const renderTaskForm = () => (
    <Box display="flex" flexDirection="column" flex="1 0 auto" paddingX={2}>
      <Task
        onClose={() => {
          vm.setTask(null);
          vm.setStep(TasksScreens.TASKS_LIST);
        }}
        district={props.district}
        task={vm.currentTask}
        tasks={props.tasks}
        poi={props.poi}
        pois={props.pois}
        returnToTaskList={() => vm.setStep(TasksScreens.TASKS_LIST)}
      />
    </Box>
  );

  const renderProOnlyFeature = () => (
    <React.Fragment>
      <InfoComponent locizePath="poi:pro_only_feature" />
      <Box display="flex" flexDirection="column" alignItems="center">
        <img src={Assets.poi.pro.tasks} alt="Pro Only Feature" style={{ width: '100%', marginTop: '30px' }} />
        <ProCtaButton />
      </Box>
    </React.Fragment>
  );

  return (
    <Box height="100%" display="flex" flexDirection="column">
      {session.hasFeatureEnabled(FEATURE.TASKS) ? (
        <React.Fragment>
          <InfoComponent locizePath="task:tasks_readonly" />
          {vm.step === TasksScreens.TASKS_LIST && renderTasksList()}
          {vm.step === TasksScreens.EDIT_TASK && renderTaskForm()}
        </React.Fragment>
      ) : (
        renderProOnlyFeature()
      )}
    </Box>
  );
});
