import { inject } from 'inversify';
import { action, makeObservable, observable, runInAction } from 'mobx';

import { AsyncTask } from '../../../../../../domain/async/AsyncTask';
import { ViewModel } from '../../../../../../domain/core/ViewModel';
import { DistrictModel } from '../../../../../../domain/model/DistrictModel';
import { PoiModel } from '../../../../../../domain/model/PoiModel';
import { TaskModel } from '../../../../../../domain/model/TaskModel';
import { TaskProxy } from '../../../../../../domain/proxy/TaskProxy';
import { I18nService } from '../../../../../../domain/service/I18nService';
import { NotificationService } from '../../../../../../domain/service/NotificationService';
import { SessionStore } from '../../../../../../domain/store/SessionStore';
import { env } from '../../../../../../env';
import { transient } from '../../../../../../inversify/decorator';
import { WORLD_MAP_ID } from '../../../../../../shared/deep-linking/DeepLink.constants';
import { FileDownloadHelper } from '../../../../../../util/FileDownloaderHelper';
import {
  TabKeyType
} from '../../../district/view-district/components/district-entries/ViewDistrictEntriesVm';

export enum TasksScreens {
  TASKS_LIST = 'TASKS_LIST',
  EDIT_TASK = 'EDIT_TASK',
}

export interface ViewTasksProps {
  pois: PoiModel[];
  district: DistrictModel | null;
  poi?: PoiModel | undefined;
  tasks: TaskModel[];
}

@transient()
export class ViewTasksVm extends ViewModel<ViewTasksProps> {

  @observable
  public step: TasksScreens = TasksScreens.TASKS_LIST;

  @observable
  public currentTask: TaskModel | null = null;

  @observable
  public activeTab: TabKeyType = 'all';

  constructor(
    @inject(I18nService) private readonly i18n: I18nService,
    @inject(TaskProxy) private readonly taskProxy: TaskProxy,
    @inject(NotificationService) private readonly notification: NotificationService,
    @inject(SessionStore) private readonly session: SessionStore,
  ) {
    super();
    makeObservable(this);
  }

  @action
  public setStep = (screen: TasksScreens) => {
    this.step = screen;
    if (screen === TasksScreens.TASKS_LIST) {
      this.activeTab = 'all'; // Reset active tab when navigating back to the list
    }
  }

  /**
   * Set the current task for editing or creating a new one
   * @param task - The task to edit, or null to create a new one
  */
  @action
  public setTask = (task: TaskModel | null) => {
    const poiId = this.props.poi?.id;
    const districtId = this.props.district?.id;

    this.currentTask = task ? task.clone() : new TaskModel(poiId, districtId);
    return this.setStep(TasksScreens.EDIT_TASK);
  }

  public toggleTaskDone = new AsyncTask(async (task: TaskModel) => {
    if (!task) return;

    try {
      task.toggleDone();
      await this.taskProxy.updateTask(task.toPutDto());
    } catch (e) {
      console.error(`error while handling task. ${e}`);
    }
  })

  /**
   * Set the active tab in the tasks list
   * @param tab - The tab key to set as active
  */
  @action
  public setActiveTab = (tab: TabKeyType) => {
    this.activeTab = tab;
  }

  public deleteTask = new AsyncTask(async (task: TaskModel) => {
    if (!task) return;

    try {
      const response = await this.taskProxy.deleteTask(task);

      if (response.ok && response.status === 204) {
        const index = this.props.tasks.findIndex((t) => t.id === task.id);
        if (index !== -1) {
          runInAction(() => {
            this.props.tasks.splice(index, 1);
          });
        }
        return this.notification.success(this.i18n.t('task:delete.success'));
      }
      this.notification.error(this.i18n.t('task:delete.error'));

    } catch (e) {
      console.error(`error while handling task. ${e}`);
      this.notification.error(this.i18n.t('task:delete.error'));
    }
  });

  public exportTasks = new AsyncTask(async () => {
    try {
      const sessionToken = this.session.session?.sessionToken;
      const districtId = this.props.district?.id;
      const userId = this.session.userId;

      if (!sessionToken) {
        throw new Error('Session token is missing.');
      }

      if (!districtId) {
        throw new Error('Selected district ID is missing.');
      }

      const queryParams = new URLSearchParams({
        token: sessionToken,
        ...(districtId !== WORLD_MAP_ID && { districtId: districtId }),
        language: this.i18n.language,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        ...(this.activeTab === 'own' && { userId: userId }),
      });
      const url = `${env.api}/data-export/tasks?${queryParams.toString()}`;

      await FileDownloadHelper.fetchAndDownloadFile(url, sessionToken, 'tasks_export.xlsx');

      this.notification.success(this.i18n.t('district:export_tasks_success'));
    } catch (error) {
      console.error('Failed to export harvests and sightings:', error);
      this.notification.error(this.i18n.t('district:export_tasks_error'));
    }
  })
}
