import { format } from 'date-fns';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Lightbox from 'yet-another-react-lightbox';
import Captions from 'yet-another-react-lightbox/plugins/captions';
import Fullscreen from 'yet-another-react-lightbox/plugins/fullscreen';

import { NoImageIcon } from '@HunterCompanion/hunter-design/icons';
import { Box, Grid, ThemeProvider, Typography } from '@mui/material';

import { PhotoModel } from '../../../../../../domain/model/PhotoModel';
import { useThemeContext } from '../../../../../../theme/ThemeContextProvider';
import { SvgWrapper } from '../../../../../../toolkit/components/SvgWrapper';

interface IViewGalleryProps {
  photos: PhotoModel[];
}

// Group photos by date and sort by time within each date group
const groupPhotosByDate = (photos: PhotoModel[]) => {
  return photos.reduce((groups, photo) => {
    const date = photo.createdAt ? format(new Date(photo.createdAt), 'dd.MM.yyyy') : 'Unknown Date';
    if (!groups[date]) groups[date] = [];
    groups[date].push(photo);
    return groups;
  }, {} as Record<string, PhotoModel[]>);
};

export const ViewImageGallery: React.FC<IViewGalleryProps> = function ViewImageGallery({ photos }: IViewGalleryProps) {
  const { theme } = useThemeContext();
  const { t } = useTranslation();
  const [lightboxIndex, setLightboxIndex] = useState<number | null>(null);

  const groupedPhotos = useMemo(() => groupPhotosByDate(photos), [photos]);
  const slides = useMemo(() => photos.map((photo, index) => ({
    src: photo.url || '',
    title: `${photo.name || `${t('poi:trail_camera.image_gallery.image.name')} ${index}`} - ${photo.createdAt ? format(new Date(photo.createdAt), 'dd.MM.yyyy HH:mm:ss') : ''}`,
  })), [photos, t]);

  const handleImageClick = useCallback((index: number) => {
    setLightboxIndex(index);
  }, []);

  const emptyState = (
    <Grid container direction="column" alignItems="center" justifyContent="center" rowGap={2}>
      <SvgWrapper icon={NoImageIcon} width={36} height={36} fill={theme.palette.brand?.brand_color} />
      <Typography variant="text_md_semibold" color={theme.palette.text.primary}>
        {t('poi:trail_camera.image_gallery.no_images')}
      </Typography>
    </Grid>
  );


  return (
    <ThemeProvider theme={theme}>
      <Box padding={2}>
        {Object.keys(groupedPhotos).length === 0 ?
          emptyState
          : <React.Fragment>
            {Object.entries(groupedPhotos).map(([date, photos]) => (
              <Grid container key={date} marginBottom={2} rowGap={1}>
                <Grid item xs={12}>
                  <Typography variant="text_md_semibold" gutterBottom color={theme.palette.text.secondary}>
                    {date}
                  </Typography>
                </Grid>
                <Grid item container spacing={2}>
                  {photos.map((photo, index) => (
                    <Grid item xs={12} sm={6} key={index}>
                      <Box
                        component="img"
                        src={photo.url || ''}
                        alt={photo.name || `${t('poi:trail_camera.image_gallery.image.name')} - ${index}`}
                        onClick={() => handleImageClick(photos.indexOf(photo))}
                        sx={{
                          width: '100%',
                          height: 120,
                          objectFit: 'cover',
                          borderRadius: 1,
                          boxShadow: 1,
                          cursor: 'pointer',
                        }}
                      />
                      <Typography variant="text_xs_regular" color={theme.palette.text.secondary} align="left">
                        {photo.createdAt && `${format(new Date(photo.createdAt), 'HH:mm')}h`}
                      </Typography>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            ))}
            <Lightbox
              slides={slides}
              open={lightboxIndex !== null}
              index={lightboxIndex || 0}
              close={() => setLightboxIndex(null)}
              plugins={[Fullscreen, Captions]}
              captions={{
                descriptionTextAlign: 'start',
              }}
            />
          </React.Fragment>
        }
      </Box>
    </ThemeProvider>
  );
};
