import { Assets } from '../assets';
import { CUSTOM_MARK_COLOR, CUSTOM_MARK_ICON, POI_TYPE } from '../shared/enum';
import { palette } from '../theme/options/palette/palette';

export interface IPoiRecord {
  [key: string]: IPoiUIModel;
}

export interface IPoiUIModel {
  i18nKey: string;
  headeri18nKey?: string;
  color?: string;
  icon: string;
  frameIcon?: string;
  maxItems?: number;
  type: POI_TYPE;
}

export const getPoiTranslation = (type: POI_TYPE): string => {
  switch (type) {
    case POI_TYPE.WEATHER:
      return 'poi:weather';
    case POI_TYPE.BAIT_SEAT:
      return 'poi:bait_seat';
    case POI_TYPE.BAIT_SEAT_MEAT:
      return 'poi:beat_seat_meat';
    case POI_TYPE.CAMERA:
      return 'poi:camera';
    case POI_TYPE.EARTHWORK:
      return 'poi:earthwork';
    case POI_TYPE.FEEDER:
      return 'poi:feeder';
    case POI_TYPE.HIDEOUT:
      return 'poi:hideout';
    case POI_TYPE.TRAP:
      return 'poi:trap';
    case POI_TYPE.BLIND:
      return 'poi:blind';
    case POI_TYPE.NEST:
      return 'poi:nest';
    case POI_TYPE.CUSTOM:
      return 'poi:custom';
    default:
      return '';
  }
};

const getHeaderPoiTranslation = (type: POI_TYPE): string => {
  switch (type) {
    case POI_TYPE.WEATHER:
      return 'pin_creation:weather.header_label';
    case POI_TYPE.BAIT_SEAT:
      return 'pin_creation:bait_seat.header_label';
    case POI_TYPE.BAIT_SEAT_MEAT:
      return 'pin_creation:beat_seat_meat.header_label';
    case POI_TYPE.CAMERA:
      return 'pin_creation:camera.header_label';
    case POI_TYPE.EARTHWORK:
      return 'pin_creation:earthwork.header_label';
    case POI_TYPE.FEEDER:
      return 'pin_creation:feeder.header_label';
    case POI_TYPE.HIDEOUT:
      return 'pin_creation:hideout.header_label';
    case POI_TYPE.TRAP:
      return 'pin_creation:trap.header_label';
    case POI_TYPE.BLIND:
      return 'pin_creation:blind.header_label';
    case POI_TYPE.NEST:
      return 'pin_creation:nest.header_label';
    case POI_TYPE.CUSTOM:
      return 'pin_creation:custom.header_label';
    default:
      return '';
  }
};

export const poiUIModels: IPoiRecord = {
  [POI_TYPE.UNKNOWN]: {
    type: POI_TYPE.UNKNOWN,
    i18nKey: getPoiTranslation(POI_TYPE.UNKNOWN),
    headeri18nKey: getHeaderPoiTranslation(POI_TYPE.UNKNOWN),
    icon: Assets.poi.fallback,
    color: CUSTOM_MARK_COLOR['#A5AEB8'],
  },
  [POI_TYPE.CUSTOM]: {
    type: POI_TYPE.CUSTOM,
    i18nKey: getPoiTranslation(POI_TYPE.CUSTOM),
    headeri18nKey: getHeaderPoiTranslation(POI_TYPE.CUSTOM),
    icon: Assets.poi.icons.custom,
  },
  [POI_TYPE.WEATHER]: {
    type: POI_TYPE.WEATHER,
    i18nKey: getPoiTranslation(POI_TYPE.WEATHER),
    headeri18nKey: getHeaderPoiTranslation(POI_TYPE.WEATHER),
    color: '#4B7BEC',
    icon: Assets.poi.icons.weather,
    maxItems: 3,
  },
  [POI_TYPE.HIDEOUT]: {
    type: POI_TYPE.HIDEOUT,
    i18nKey: getPoiTranslation(POI_TYPE.HIDEOUT),
    headeri18nKey: getHeaderPoiTranslation(POI_TYPE.HIDEOUT),
    color: palette.background?.poi?.hideout,
    frameIcon: Assets.poi.highseat,
    icon: Assets.poi.icons.high_seat,
  },
  [POI_TYPE.CAMERA]: {
    type: POI_TYPE.CAMERA,
    i18nKey: getPoiTranslation(POI_TYPE.CAMERA),
    headeri18nKey: getHeaderPoiTranslation(POI_TYPE.CAMERA),
    color: palette.background?.poi?.camera,
    frameIcon: Assets.poi.camera,
    icon: Assets.poi.icons.camera,
  },
  [POI_TYPE.FEEDER]: {
    type: POI_TYPE.FEEDER,
    i18nKey: getPoiTranslation(POI_TYPE.FEEDER),
    headeri18nKey: getHeaderPoiTranslation(POI_TYPE.FEEDER),
    color: palette.background?.poi?.feeder,
    frameIcon: Assets.poi.feedingsite,
    icon: Assets.poi.icons.feeding_site,
  },
  [POI_TYPE.BAIT_SEAT]: {
    type: POI_TYPE.BAIT_SEAT,
    i18nKey: getPoiTranslation(POI_TYPE.BAIT_SEAT),
    headeri18nKey: getHeaderPoiTranslation(POI_TYPE.BAIT_SEAT),
    color: palette.background?.poi?.bait_seat,
    frameIcon: Assets.poi.baitsiteVege,
    icon: Assets.poi.icons.bait_site_veg,
  },
  [POI_TYPE.BAIT_SEAT_MEAT]: {
    type: POI_TYPE.BAIT_SEAT_MEAT,
    i18nKey: getPoiTranslation(POI_TYPE.BAIT_SEAT_MEAT),
    headeri18nKey: getHeaderPoiTranslation(POI_TYPE.BAIT_SEAT_MEAT),
    color: palette.background?.poi?.bait_seat_meat,
    frameIcon: Assets.poi.baitsiteMeat,
    icon: Assets.poi.icons.bait_site_meat,
  },
  [POI_TYPE.EARTHWORK]: {
    type: POI_TYPE.EARTHWORK,
    i18nKey: getPoiTranslation(POI_TYPE.EARTHWORK),
    headeri18nKey: getHeaderPoiTranslation(POI_TYPE.EARTHWORK),
    color: palette.background?.poi?.earthwork,
    frameIcon: Assets.poi.burrow,
    icon: Assets.poi.icons.earth_work,
  },
  [POI_TYPE.TRAP]: {
    type: POI_TYPE.TRAP,
    i18nKey: getPoiTranslation(POI_TYPE.TRAP),
    headeri18nKey: getHeaderPoiTranslation(POI_TYPE.TRAP),
    color: palette.background?.poi?.trap,
    frameIcon: Assets.poi.trap,
    icon: Assets.poi.icons.trap,
  },
  [POI_TYPE.BLIND]: {
    type: POI_TYPE.BLIND,
    i18nKey: getPoiTranslation(POI_TYPE.BLIND),
    headeri18nKey: getHeaderPoiTranslation(POI_TYPE.BLIND),
    color: palette.background?.poi?.blind,
    frameIcon: Assets.poi.trapblind,
    icon: Assets.poi.icons.blind,
  },
  [POI_TYPE.NEST]: {
    type: POI_TYPE.NEST,
    i18nKey: getPoiTranslation(POI_TYPE.NEST),
    headeri18nKey: getHeaderPoiTranslation(POI_TYPE.NEST),
    color: palette.background?.poi?.nest,
    frameIcon: Assets.poi.nest,
    icon: Assets.poi.icons.nest,
  },
};

export function getCustomMarkIcon(icon: CUSTOM_MARK_ICON) {
  switch (icon) {
    case CUSTOM_MARK_ICON.BEEHIVE:
      return Assets.customMark.gray.beehive_icon;
    case CUSTOM_MARK_ICON.BEETLE:
      return Assets.customMark.gray.beetle_icon;
    case CUSTOM_MARK_ICON.FAVORITE_STAR:
      return Assets.customMark.gray.favourite_star_icon;
    case CUSTOM_MARK_ICON.HEART:
      return Assets.customMark.gray.heart_icon;
    case CUSTOM_MARK_ICON.HIGHSTAND_LADDER:
      return Assets.customMark.gray.highstand_ladder_icon;
    case CUSTOM_MARK_ICON.HUT:
      return Assets.customMark.gray.hut_icon;
    case CUSTOM_MARK_ICON.INFO:
      return Assets.customMark.gray.information_icon;
    case CUSTOM_MARK_ICON.MOBILE_HIGHSTAND:
      return Assets.customMark.gray.mobile_highstand_icon;
    case CUSTOM_MARK_ICON.NO_ENTRY:
      return Assets.customMark.gray.no_entry_icon;
    case CUSTOM_MARK_ICON.PUDDLE:
      return Assets.customMark.gray.puddle_icon;
    case CUSTOM_MARK_ICON.RACK:
      return Assets.customMark.gray.rack_icon;
    case CUSTOM_MARK_ICON.RUT:
      return Assets.customMark.gray.rut_icon;
    case CUSTOM_MARK_ICON.SLAT:
      return Assets.customMark.gray.salt_icon;
    case CUSTOM_MARK_ICON.SOS:
      return Assets.customMark.gray.sos_icon;
    case CUSTOM_MARK_ICON.WARNING:
      return Assets.customMark.gray.warning_icon;
    case CUSTOM_MARK_ICON.WATER_SUPPLY:
      return Assets.customMark.gray.water_supply_icon;
    case CUSTOM_MARK_ICON.BIOTOPE:
      return Assets.customMark.gray.biotope_icon;
    case CUSTOM_MARK_ICON.BLIND:
      return Assets.customMark.gray.blind_icon;
    case CUSTOM_MARK_ICON.BRIDGE:
      return Assets.customMark.gray.bridge_icon;
    case CUSTOM_MARK_ICON.CLOSED_HIGHSEAT:
      return Assets.customMark.gray.closed_highseat_icon;
    case CUSTOM_MARK_ICON.LADDER:
      return Assets.customMark.gray.ladder_icon;
    case CUSTOM_MARK_ICON.MEETING_POINT:
      return Assets.customMark.gray.meeting_point_icon;
    case CUSTOM_MARK_ICON.OPEN_SEAT:
      return Assets.customMark.gray.open_seat_icon;
    case CUSTOM_MARK_ICON.PARKING:
      return Assets.customMark.gray.parking_icon;
    case CUSTOM_MARK_ICON.SCISSOR_LADDER:
      return Assets.customMark.gray.scissor_ladder_icon;
    case CUSTOM_MARK_ICON.HIDE_BUCK:
      return Assets.customMark.gray.hide_buck_icon;
    case CUSTOM_MARK_ICON.OPEN_HIGHSEAT:
      return Assets.customMark.gray.open_highseat_icon;
    case CUSTOM_MARK_ICON.NESTING_BOX:
      return Assets.customMark.gray.nesting_box;
    case CUSTOM_MARK_ICON.BLOOD_TRAIL:
      return Assets.customMark.gray.blood_trail;
  }
}
