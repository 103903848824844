import { inject } from 'inversify';
import { action, computed, makeObservable, observable } from 'mobx';

import { ViewModel } from '../../../../../../../domain/core/ViewModel';
import { DistrictModel } from '../../../../../../../domain/model/DistrictModel';
import { PoiModel } from '../../../../../../../domain/model/PoiModel';
import { TaskModel } from '../../../../../../../domain/model/TaskModel';
import { I18nService } from '../../../../../../../domain/service/I18nService';
import { SessionStore } from '../../../../../../../domain/store/SessionStore';
import { transient } from '../../../../../../../inversify/decorator';
import {
  TabKeyType
} from '../../../../district/view-district/components/district-entries/ViewDistrictEntriesVm';

export interface ITaskListProps {
  poi?: PoiModel | undefined;
  pois: PoiModel[];
  tasks: TaskModel[];
  district: DistrictModel | null;
  onEditTask: (task: TaskModel) => void;
  onDeleteTask: (task: TaskModel) => void;
  markAsCompleted: (task: TaskModel) => void;
  onTabChange: (activeTab: TabKeyType) => void;
}

export interface ITaskTab {
  key: TabKeyType;
  text: string;
}

@transient()
export class TaskListVm extends ViewModel<ITaskListProps> {

  @observable
  public activeTab: TabKeyType = 'all';

  @observable
  public showCompletedTasks: boolean = false;

  constructor(
    @inject(I18nService) private readonly i18n: I18nService,
    @inject(SessionStore) private readonly session: SessionStore,
  ) {
    super();
    makeObservable(this);
  }

  @computed
  public get showDividerLine(): boolean {
    const tasks = this.props.poi
      ? this.props.tasks.filter((task) => task.poiId === this.props.poi?.id)
      : this.props.tasks;

    return tasks.length > 0;
  }

  @computed
  public get tabs(): ITaskTab[] {
    return [
      { key: 'all', text: this.i18n.t('task:all_tasks') },
      { key: 'own', text: this.i18n.t('task:own_tasks') },
    ];
  }

  @computed
  public get tasks(): TaskModel[] {
    /**
     * If it's PoiView -> filter tasks linked to that POI
     * If it's DistrictView -> use district tasks
    */
    const filteredTasks = this.props.poi
      ? this.props.tasks.filter((task) => task.poiId === this.props.poi?.id)
      : this.props.tasks;

    if (this.activeTab === 'own') {
      return filteredTasks.filter((task) =>
        task.assigneesIds.includes(this.session.userId)
      );
    }

    return filteredTasks;
  }

  /**  Returns tasks that are not completed.  */
  @computed
  public get withoutCompletedTasks(): TaskModel[] {
    return this.tasks.filter(task => !task.done);
  }

  @action
  public changeTab = (_e: unknown, tab: TabKeyType) => {
    this.activeTab = tab;
    if (this.props.onTabChange) {
      this.props.onTabChange(tab); // Notify parent component
    }
  }

  @action
  public toggleCompletedTasks = () => {
    this.showCompletedTasks = !this.showCompletedTasks;
  }

}
