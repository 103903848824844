import { ApiProperty } from '@nestjs/swagger';

import { UserResponseDto } from './user.response.dto';

export class PhotoResponseDto {

  @ApiProperty()
  public id: string;

  @ApiProperty({ type: () => UserResponseDto })
  public owner?: UserResponseDto;

  @ApiProperty()
  public name: string;

  @ApiProperty({ required: false })
  public entryId?: string;

  @ApiProperty({ required: false })
  public poiId?: string;

  @ApiProperty()
  public url: string | null;

  @ApiProperty()
  public thumbnailUrl: string | null;

  @ApiProperty()
  public createdAt?: string;

  @ApiProperty({ required: false })
  public uploadedPhoto?: boolean;
}
