import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { Box, Tab, Tabs, ThemeProvider } from '@mui/material';

import { useVm } from '../../../../../../../domain/hooks/useVm';
import { useThemeContext } from '../../../../../../../theme/ThemeContextProvider';
import {
  NewSubmitButton
} from '../../../../../../../toolkit/components/redesign-components/NewSubmitButton';
import { DistrictEntryListItem } from './DistrictEntryListItem';
import { IViewDistrictEntriesProps, ViewDistrictEntriesVm } from './ViewDistrictEntriesVm';

export const ViewDistrictEntries = observer(function ViewDistrictEntries(props: IViewDistrictEntriesProps) {
  const vm = useVm(ViewDistrictEntriesVm, props);
  const { theme } = useThemeContext();
  const { t } = useTranslation();

  /**
   * Renders the tabs for switching between "All" and "Own" entries.
  */
  const renderTabs = () => (
    <Box sx={{ boxShadow: 1 }}>
      <Tabs
        value={vm.activeTab}
        onChange={vm.changeTab}
        variant="fullWidth"
        scrollButtons
        indicatorColor="secondary"
        sx={{ '.MuiTabs-scrollButtons.Mui-disabled': { opacity: 0.3 } }}
      >
        {vm.tabs.map((tab) => (
          <Tab
            key={tab.key}
            value={tab.key}
            label={`${tab.text} (${tab.count})`}
          />
        ))}
      </Tabs>
    </Box>
  );

  /**
   * Renders the list of district entries.
  */
  const renderEntries = () => (
    <Box flexGrow={1} paddingX={2}>
      {vm.entries.map((entry, index) => (
        <DistrictEntryListItem
          key={entry.id}
          entry={entry}
          lastItem={index === vm.entries.length - 1}
          onEdit={props.onEdit}
          onDelete={props.onDelete}
          canEditOrDeleteEntry={vm.canEditOrDeleteEntry(entry)}
        />
      ))}
    </Box>
  );

  /**
   * Renders the export button at the bottom of the screen.
  */
  const renderExportButton = () => (
    <ThemeProvider theme={theme}>
      <Box
        marginTop="auto"
        position="sticky"
        bottom={0}
        bgcolor="white"
        borderTop={1}
        display="flex"
        justifyContent="center"
        alignItems="center"
        paddingX={4}
        minHeight="70px"
      >
        <NewSubmitButton
          color="secondary"
          size="large"
          onClick={vm.exportHarvestsAndSightings.run}
          disabled={vm.entries.length === 0}
          submitting={vm.exportHarvestsAndSightings.isBusy}
        >
          {t('district:harvest_export.cta')}
        </NewSubmitButton>
      </Box>
    </ThemeProvider>
  );

  return (
    <Box height="100%" display="flex" flexDirection="column">
      {renderTabs()}
      {renderEntries()}
      {renderExportButton()}
    </Box>
  );
});
