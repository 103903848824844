import { observer } from 'mobx-react-lite';
import { useCallback, useMemo } from 'react';

import { Avatar, Typography } from '@mui/material';

import { Assets } from '../../../../../assets';
import { IGoogleMapProps } from '../../../../../domain/core/IGoogleMapProps';
import { useVm } from '../../../../../domain/hooks/useVm';
import { PoiModel } from '../../../../../domain/model/PoiModel';
import { OptionalTooltip } from '../../../../../toolkit/components/OptionalTooltip';
import { usePinSizeContext } from '../../google-map/hooks/usePinSizeContext';
import { Marker } from '../../Marker';
import { PoiLabel } from '../../PoiLabel';
import { MarkerTopLeftIcon } from '../MarkerTopLeftIcon';
import { PoiTooltip } from '../PoiTooltip';
import { PoiMarkerVm } from './PoiMarkerVm';

export interface PoiMarkerProps extends IGoogleMapProps {
  poi: PoiModel;
  onClick?: (poi: PoiModel) => void;
  opacity: number;
  skiptransform?: boolean;
  hideTooltip?: boolean;
  width?: number;
  miniIconWidth?: number;
  symbolWidth?: number;
  shootingDirectionWidth?: number;
  icon?: string;
  hideShootingDirection?: boolean;
  showPoiLabels?: boolean;
}



export const PoiMarker = observer(function PoiMarker(props: PoiMarkerProps) {
  const vm = useVm(PoiMarkerVm, props);
  const { pinWidth } = usePinSizeContext();

  const generateInitials = (firstName: string, lastName: string): string =>
    `${firstName.charAt(0).toUpperCase()}${lastName.charAt(0).toUpperCase()}`;

  // if explicit icon is provided, use that one
  // if poi type is custom, and user does not have custom marks enabled
  // show him fallback icon
  const getIcon = () => props.icon || (vm.isCustomPoi && !vm.hasCustomMarksEnabled ? Assets.poi.custom_pro : props.poi.icon);

  const icon = useMemo(getIcon, [props.icon, vm.isCustomPoi, vm.hasCustomMarksEnabled, props.poi.icon]);
  const color = useMemo(() => vm.hasCustomMarksEnabled ? props.poi.customMark?.background : undefined, [vm.hasCustomMarksEnabled, props.poi.customMark]);

  const handleClick = useCallback(() => {
    if (props.poi.id) props.onClick?.(props.poi);
  }, [props]);

  const renderShootingDirection = () => {
    if (
      !props.hideShootingDirection &&
      vm.hasShootingDirectionEnabled &&
      props.poi.shootingDirection != null &&
      props.poi.shootingAngle
    ) {
      const markerHeight = props.width || pinWidth;

      // Center the semicircle at the marker's tip
      const translateX = 0; // No horizontal shift
      const translateY = markerHeight * 0.6; // Position at the marker's tip

      // Rotation angle for the shooting direction
      const rotationAngle = props.poi.shootingDirection - props.poi.shootingAngle / 2;

      const transform = `translate(${translateX}px, ${translateY}px) rotate(${rotationAngle}deg)`;

      return (
        <img
          style={{
            position: 'absolute',
            zIndex: 1,
            transform,
            opacity: props.opacity,
            width: props.shootingDirectionWidth ?? '140px',
          }}
          src={props.poi.shootingAngleImage}
          alt="shooting direction"
        />
      );
    }
    return null;
  };

  const renderCustomMarkForeground = () => {
    if (props.poi.customMark && props.poi.customMark.foreground && vm.hasCustomMarksEnabled) {
      return (
        <img
          style={{
            position: 'absolute',
            top: '18%',
            width: props.symbolWidth || pinWidth * 0.6,
            opacity: props.opacity,
            zIndex: 3,
          }}
          src={props.poi.customMark.foregroundIcon}
          alt="custom mark"
        />
      );
    }
    return null;
  };

  const renderMarkerTopLeftIcon = () => {
    const miniIconStyle = {
      width: '100%',
      opacity: props.opacity,
      zIndex: 4,
    };

    if (props.poi.booking && vm.hasBookingsEnabled) {
      return (
        <MarkerTopLeftIcon width={props.miniIconWidth} opacity={props.opacity}>
          <img
            style={miniIconStyle}
            src={props.poi.booking.user.photo?.thumbnailUrl ?? undefined}
            alt="booking"
          />
        </MarkerTopLeftIcon>
      );
    }

    if (props.poi.blocked && vm.hasBookingsEnabled) {
      return (
        <MarkerTopLeftIcon width={props.miniIconWidth} opacity={props.opacity}>
          <img style={miniIconStyle} src={Assets.poi.blocked} alt="blocked" />
        </MarkerTopLeftIcon>
      );
    }

    if (!vm.hasBookingsEnabled && (props.poi.blocked || props.poi.booking?.user)) {
      return (
        <MarkerTopLeftIcon width={props.miniIconWidth} opacity={props.opacity}>
          <img style={miniIconStyle} src={Assets.poi.blocked_non_pro} alt="non-pro blocked" />
        </MarkerTopLeftIcon>
      );
    }

    if (props.poi.usersCheckedIn.length === 1) {
      const user = props.poi.usersCheckedIn[0].user;
      return (
        <MarkerTopLeftIcon width={props.miniIconWidth} opacity={props.opacity}>
          <Avatar
            sx={{
              bgcolor: 'orange',
              zIndex: 4,
              width: '100%',
              height: '100%',
              opacity: props.opacity,
            }}
          >
            <Typography variant="subtitle2">{generateInitials(user.firstName, user.lastName)}</Typography>
          </Avatar>
        </MarkerTopLeftIcon>
      );
    }

    if (props.poi.usersCheckedIn.length > 1) {
      return (
        <MarkerTopLeftIcon width={props.miniIconWidth} opacity={props.opacity}>
          <img
            style={miniIconStyle}
            src={Assets.poi.multiple_checkin_icon}
            alt="multiple check-in"
          />
        </MarkerTopLeftIcon>
      );
    }

    return null;
  };

  return (
    <OptionalTooltip
      enabled={!props.hideTooltip}
      arrow
      placement="top"
      title={<PoiTooltip vm={vm} />}
      componentsProps={{
        arrow: {
          sx: { '::before': { backgroundColor: vm.backgroundColor } },
        },
      }}
    >
      <Marker
        lat={props.lat}
        lng={props.lng}
        icon={icon}
        color={color ?? undefined}
        onClick={handleClick}
        opacity={props.opacity}
        skiptransform={props.skiptransform}
        width={props.width || pinWidth}
        zIndex={2}
      >
        {props.showPoiLabels && (
          <PoiLabel name={vm.poiHelper.truncateString(props.poi.name, 22)} />
        )}
        {renderShootingDirection()}
        {renderCustomMarkForeground()}
        {renderMarkerTopLeftIcon()}
      </Marker>
    </OptionalTooltip>
  );
});
