import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Menu,
  MenuItem, Tooltip, Typography
} from '@mui/material';

import { Assets } from '../../../../../../../assets';
import { PoiModel } from '../../../../../../../domain/model/PoiModel';
import { POI_TYPE } from '../../../../../../../shared/enum';
import { PoiMarker } from '../../../../poi/marker/PoiMarker';
import { DistrictViewListItem } from '../DistrictViewListItem';
import { MenuItemTooltip } from '../MenuItemTooltip';
import { IPoiTab, PoiTabType } from '../../../../poi/new-poi/NewPoiVm';

interface IProps {
  poi: PoiModel;
  lastItem: boolean;
  onViewDetails: (poi: PoiModel) => void;
  onEdit: (poi: PoiModel, tab: IPoiTab) => void;
  onDelete: (poi: PoiModel) => void;
  canEditAndDeletePoi: boolean;
}

export function DistrictPoiListItem(props: IProps) {
  const { t } = useTranslation();
  const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | HTMLElement>(null);
  const menuOpen = Boolean(menuAnchorEl);
  const [openDialog, setOpenDialog] = React.useState(false);

  const showMenu = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setMenuAnchorEl(event.currentTarget);
  }, []);

  const closeMenu = useCallback((event: React.MouseEvent) => {
    event.stopPropagation();
    setMenuAnchorEl(null);
  }, []);

  const viewPoiDetails = useCallback((event: React.MouseEvent) => {
    closeMenu(event);
    props.onViewDetails(props.poi);
  }, [closeMenu, props]);

  const edit = useCallback((event: React.MouseEvent) => {
    closeMenu(event);
    props.onEdit(props.poi, { type: PoiTabType.DETAILS, enabled: true, });
  }, [closeMenu, props]);

  const handleOpenDeleteDialog = useCallback(() => setOpenDialog(true), []);

  const handleCloseDeleteDialog = useCallback((event: React.MouseEvent) => {
    setOpenDialog(false);
    closeMenu(event);
  }, [closeMenu]);

  return (
    <DistrictViewListItem
      component={<Box width="50px">
        <PoiMarker
          poi={props.poi}
          lat={0}
          lng={0}
          hideShootingDirection
          // in case of weather poi we provide explicit icon
          // otherwise it is automatically selected based on poi type
          icon={props.poi.type === POI_TYPE.WEATHER
            ? Assets.weather.poi_weather_loading
            : undefined
          }
          opacity={1}
          skiptransform={true}
          hideTooltip
          showPoiLabels={false}
        />
      </Box>}
      secondLine={props.poi.name}
      thirdLine={t(props.poi.typeI18nKey)}
      withDivider={!props.lastItem}
      rightComponent={
        <>
          <Button
            onClick={showMenu}
            color="secondary"
          >
            {t('district:options_dropdown')}
          </Button>
          <Menu
            anchorEl={menuAnchorEl}
            open={menuOpen}
            onClose={closeMenu}
          >
            {/* VIEW DETAILS */}
            {props.poi.type !== POI_TYPE.WEATHER && <MenuItem
              onClick={viewPoiDetails}
              disabled={!props.canEditAndDeletePoi}
            >
              {t('poi:menu_option.view_poi_details')}
            </MenuItem>}

            {/* EDIT POI */}
            {props.poi.type !== POI_TYPE.WEATHER &&
              (props.canEditAndDeletePoi ?
                <MenuItem
                  onClick={edit}
                  disabled={!props.canEditAndDeletePoi}
                >{t('poi:menu_option.edit_poi')}</MenuItem>
                : <Tooltip placement="right" title={<MenuItemTooltip message={t('poi:menu_option.edit_poi_tooltip')} />}>
                  <div>
                    <MenuItem
                      onClick={edit}
                      disabled={!props.canEditAndDeletePoi}
                    >{t('poi:menu_option.edit_poi')}</MenuItem>
                  </div>
                </Tooltip>)}

            {/* DELETE POI */}
            {props.canEditAndDeletePoi ?
              <MenuItem
                onClick={handleOpenDeleteDialog}
                disabled={!props.canEditAndDeletePoi}
              >{t('poi:menu_option.delete_poi')}</MenuItem>
              : <Tooltip placement="right" title={<MenuItemTooltip message={t('poi:menu_option.delete_poi_tooltip')} />}>
                <div>
                  <MenuItem
                    onClick={handleOpenDeleteDialog}
                    disabled={!props.canEditAndDeletePoi}
                  >{t('poi:menu_option.delete_poi')}</MenuItem>
                </div>
              </Tooltip>}

            <Dialog open={openDialog} onClose={handleCloseDeleteDialog}>
              <DialogTitle sx={{ fontSize: '1.125rem', fontWeight: '500' }}>
                {t('poi:delete.title')}
              </DialogTitle>
              <DialogContent>
                <DialogContentText variant="subtitle1">{t('poi:delete.description')}</DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseDeleteDialog}>
                  <Typography variant="subtitle2">{t('poi:delete.disagree')}</Typography>
                </Button>
                <Button
                  onClick={(event: React.MouseEvent) => {
                    props.onDelete(props.poi);
                    handleCloseDeleteDialog(event);
                  }}
                  autoFocus
                ><Typography variant="subtitle2" color={'text.hunter_green'}>{t('poi:delete.agree')}</Typography>
                </Button>
              </DialogActions>
            </Dialog>
          </Menu>
        </>}
    />
  );
}
