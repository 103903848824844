import { add, differenceInDays, format } from 'date-fns';
import { inject } from 'inversify';
import { computed, makeObservable } from 'mobx';

import { AsyncTask } from '../../../domain/async/AsyncTask';
import { GroupSubscriptionUserModel } from '../../../domain/model/GroupSubscriptionUserModel';
import { I18nService } from '../../../domain/service/I18nService';
import { NotificationService } from '../../../domain/service/NotificationService';
import { StripeService } from '../../../domain/service/StripeService';
import { SessionStore } from '../../../domain/store/SessionStore';
import { transient } from '../../../inversify/decorator';
import {
  GroupSubscriptionPostRequestDto
} from '../../../shared/dto/subscription/groupSubscription.post.request.dto';
import { GroupPricingVm } from '../GroupPricingVm';
import { GroupOverviewTypeEnum, IGroupOverviewWithOwner } from '../types/GroupOverviewTypes';

@transient()
export class GroupSubscriptionVm extends GroupPricingVm {

  constructor(
    @inject(I18nService) protected override readonly i18n: I18nService,
    @inject(NotificationService) protected override readonly notification: NotificationService,
    @inject(StripeService) protected override readonly stripeService: StripeService,
    @inject(SessionStore) public override readonly sessionStore: SessionStore,
  ) {
    super(stripeService, i18n, notification, sessionStore);
    makeObservable(this);
  }

  public override onInit = async () => {
    await this.fetchData.run();
  }

  public fetchData = new AsyncTask(async () => {
    await Promise.all([
      this.getProducts.run(),
      this.getRelatedMembers.run()
    ]);
  });

  @computed
  public get startDate(): string {
    return format(new Date(), 'dd.MM.yyyy');
  }

  @computed
  public get endDate(): string {
    return format(add(new Date(), { years: 1 }), 'dd.MM.yyyy');
  }

  @computed
  public get groupOwner(): GroupSubscriptionUserModel | undefined {
    return this.areaRelatedMembers.find(member => member.id === this.sessionStore.userId);
  }

  /** Represents group owner plus all selected users in overview table */
  @computed
  public get groupMembersOverview(): Set<GroupSubscriptionUserModel> {
    return this.groupOwner ? new Set([this.groupOwner, ...Array.from(this.assignedMembers)]) : new Set([...Array.from(this.assignedMembers)]);
  }

  @computed
  public get isOwnerAlreadyPro(): boolean {
    const owner = this.groupOwner;
    return owner?.subscription ? Boolean(owner.isYearlySubscription) : false;
  }

  /**
   * The discount applies only if the user is already a "Pro" member with an active yearly subscription. If eligible, the discount is
   * calculated based on the number of days remaining until the active yearly subscription expires, proportionate to a full year. If the user
   * is not a "Pro" member or if the expiration date is unavailable, the discount is set to zero.
  */
  @computed
  public get ownerDiscount(): number {
    if (!this.isOwnerAlreadyPro || !this.groupOwner?.subscription?.expiresAt) {
      return 0;
    }

    const today = new Date();
    const expirationDate = new Date(this.groupOwner.subscription.expiresAt);
    const daysLeftUntilRenewal = differenceInDays(expirationDate, today);

    const daysInYear = 365;
    const discount = (daysLeftUntilRenewal / daysInYear) * this.ownerPriceWithoutDiscount;

    return discount;
  }

  @computed
  public get ownerFinalPrice(): number {
    return this.ownerPriceWithoutDiscount - this.ownerDiscount;
  }

  @computed
  public get assignedMembersDiscount(): number {
    return Array.from(this.assignedMembers).reduce((acc, member) => {
      return member.subscription && member.isYearlySubscription
        ? acc + this.priceForEveryNextUser
        : acc;
    }, 0);
  }

  @computed
  public get assignedMembersFinalPrice(): number {
    return Math.max(0, this.assignedMembersPriceWithoutDiscount - this.assignedMembersDiscount);
  }

  // Calculates the total price without discount for the group, including the owner
  @computed
  public get groupTotalPriceWithoutDiscount(): number {
    return this.groupMembersOverview.size === 1
      ? this.ownerPriceWithoutDiscount
      : this.ownerPriceWithoutDiscount + this.assignedMembersPriceWithoutDiscount;
  }

  // Calculates the total discount for the group, including the owner
  @computed
  public get groupTotalDiscount(): number {
    return this.ownerDiscount + this.assignedMembersDiscount;
  }

  // Calculates the final total price after applying discounts, including the owner
  @computed
  public get groupTotalPriceAfterDiscount(): number {
    return this.ownerFinalPrice + this.assignedMembersFinalPrice;
  }

  @computed
  public get groupOverviewWithOwner(): IGroupOverviewWithOwner {
    return {
      type: GroupOverviewTypeEnum.WithOwner,
      groupMembersOverview: this.groupMembersOverview,
      assignedMembers: this.assignedMembers,
      candidatesForAssignment: this.candidatesForAssignment,
      groupTotalPriceWithoutDiscount: this.groupTotalPriceWithoutDiscount,
      groupTotalDiscount: this.groupTotalDiscount,
      groupTotalPriceAfterDiscount: this.groupTotalPriceAfterDiscount,
      currency: this.currency,
      priceForEveryNextUser: this.priceForEveryNextUser,
      owner: this.groupOwner,
      ownerPriceWithoutDiscount: this.ownerPriceWithoutDiscount,
      ownerDiscount: this.ownerDiscount,
      ownerFinalPrice: this.ownerFinalPrice,
    };
  }

  /**
   * This createCheckoutSession is used only for GROUP products.
   * For single products we use one from 'PricingRouteVm'.
  */
  public createCheckoutSession = new AsyncTask(async (): Promise<void> => {
    if (!this.product) {
      throw new Error('something went wrong');
    }

    if (this.groupMembersOverview.size < 2) {
      return;
    }

    const groupSubscriptionPostRequestDto: GroupSubscriptionPostRequestDto = {
      productId: this.product.id,
      priceId: this.product.price.priceId,
      members: Array.from(this.groupMembersOverview, groupMember => groupMember.id)
        .filter(id => id && id !== this.groupOwner?.id), // Exclude owner's ID and undefined IDs
      quantity: this.groupMembersOverview.size,
      discount: this.groupTotalDiscount,
    };

    return await this.stripeService.createCheckoutSessionForGroupProduct(groupSubscriptionPostRequestDto);
  });

}
