import { observer } from 'mobx-react-lite';
import React, { BaseSyntheticEvent, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Box, Button, Grid, Link, MenuItem, TextField, ThemeProvider, Typography
} from '@mui/material';

import { useContainer } from '../../../../../../domain/hooks/useContainer';
import { useVm } from '../../../../../../domain/hooks/useVm';
import { PoiModel } from '../../../../../../domain/model/PoiModel';
import { TrackingEvent } from '../../../../../../domain/service/tracking/TrackingEvent';
import { TrackingService } from '../../../../../../domain/service/tracking/TrackingService';
import { POI_CONNECTION_STATUS } from '../../../../../../shared/enum/poiConnection.enum';
import { useThemeContext } from '../../../../../../theme/ThemeContextProvider';
import { CopyToClipboard } from '../../../../../../toolkit/components/CopyToClipboard';
import { InfoComponent } from '../../../../../../toolkit/components/InfoComponent';
import { CAMERA_MANUFACTURER_ENUM } from '../../../../../../toolkit/enums/cameraManufacturerEnum';
import { ViewConnectCameraStep, ViewConnectCameraVm } from './ViewConnectCameraVm';

export interface IViewConnectCameraProps {
  poi: PoiModel;
  onRemoveConnection: (poiId: string) => void;
}

export const ViewConnectCamera = React.memo(observer(function ViewConnectCamera({ poi, onRemoveConnection }: IViewConnectCameraProps) {
  const { theme } = useThemeContext();
  const { t } = useTranslation();
  const vm = useVm(ViewConnectCameraVm, { poi, onRemoveConnection });
  const tracking = useContainer().get(TrackingService);

  const handleManufacturerChange = useCallback((event: BaseSyntheticEvent) => {
    vm.setCameraManufacturer(event.target.value);
  }, [vm]);

  const handleConnectCamera = useCallback(() => {
    vm.setStep(ViewConnectCameraStep.CONNECTED_CAM);
    tracking.track(TrackingEvent.TRAILCAM_CONNECT_SELECTED);
  }, [tracking, vm]);

  const cameraManufacturers = useMemo(() => Object.values(CAMERA_MANUFACTURER_ENUM), []);

  const manufacturerSelectComponent = (
    <TextField
      value={vm.cameraManufacturer}
      select
      fullWidth
      disabled
      label={t('poi:trail_camera.connect_camera.selector.label')}
      InputProps={{ disableUnderline: false }}
      onChange={handleManufacturerChange}
      sx={{
        backgroundColor: 'transparent',
        border: 'none',
        paddingLeft: 0,
      }}
    >
      {cameraManufacturers.map((manufacturer) => (
        <MenuItem key={manufacturer} value={manufacturer}>
          {manufacturer}
        </MenuItem>
      ))}
    </TextField>
  );

  const renderConnectionInfo = (
    <Grid container direction="column" rowGap={1} paddingTop={4} paddingX={2}>
      <Grid item paddingBottom={2}>{manufacturerSelectComponent}</Grid>

      <Grid item container justifyContent="space-between" gap={1}>
        <Grid item xs={9}>
          <Typography variant="text_sm_semibold" color={theme.palette.text.primary}>
            {t('poi:trail_camera.connect_camera.heading')}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Link variant="text_sm_semibold" color={theme.palette.buttons?.primary}
            target={'_blank'}
            href={t('links:trail_cam_how_to')}
            sx={{ textDecoration: 'none' }}
          >
            {t('poi:trail_camera.connect_camera.how_to')}
          </Link>
        </Grid>
      </Grid>

      <Grid item container justifyContent="space-between" alignItems="center" gap={2} bgcolor={theme.palette.background.container} borderRadius={1.5} padding={1}>
        <Grid item xs={10}>
          <Typography variant="text_sm_regular" color={theme.palette.text.primary}>
            {poi.cameraEmail}
          </Typography>
        </Grid>
        <Grid item xs textAlign="end">
          <CopyToClipboard tooltipText={poi.cameraEmail ?? ''} iconSize={16} trackingEvent={TrackingEvent.TRAILCAM_COPYTOCLIPBOARD_SELECTED} />
        </Grid>
      </Grid>

      <Grid item>
        <Typography variant="text_sm_regular" color={theme.palette.text.primary}>
          {t('poi:trail_camera.connect_camera.description')}
        </Typography>
      </Grid>
      {poi.connectionStatus !== POI_CONNECTION_STATUS.CONNECTED && <Grid item>
        <Typography variant="text_xs_regular" color={theme.palette.background.error_default}>
          {t('poi:trail_camera.connect_camera.note')}
        </Typography>
      </Grid>}

      <Grid item paddingTop={3}>
        <Typography variant="text_sm_semibold" color={theme.palette.text.primary}>
          {t('poi:trail_camera.connect_camera.status.label')}&nbsp;
        </Typography>
        {poi.connectionStatus === POI_CONNECTION_STATUS.CONNECTED
          ? <Typography variant="text_sm_regular" color={theme.palette.buttons?.primary}>
            {t('poi:trail_camera.connect_camera.status.connected')}
          </Typography>
          : <Typography variant="text_sm_regular" color={theme.palette.text.primary}>
            {t('poi:trail_camera.connect_camera.status.unconnected')}
          </Typography>}
      </Grid>

      {
        poi.connectionStatus === POI_CONNECTION_STATUS.CONNECTED &&
        <Grid item>
          <Typography
            variant='text_sm_semibold'
            color={theme.palette.background.error_default}
            onClick={vm.removeConnection.run}
            sx={{ cursor: 'pointer' }}
          >
            {t('poi:trail_camera.connect_camera.remove_connection.cta')}
          </Typography>
        </Grid>}
    </Grid>
  );

  const renderNotConnectedContent = (
    <>
      <InfoComponent locizePath="poi:trail_camera.connect_camera.info_component" />
      <Box padding={4}>
        <Button fullWidth color="secondary" onClick={handleConnectCamera}>
          {t('poi:trail_camera.connect_camera.button')}
        </Button>
      </Box>
      <Grid container direction="column" paddingY={4} paddingX={2} />
    </>
  );

  useEffect(() => {
    if (poi.connectionStatus === POI_CONNECTION_STATUS.CONNECTED) {
      vm.setStep(ViewConnectCameraStep.CONNECTED_CAM);
    }
  }, [poi.connectionStatus, vm]);

  return (
    <ThemeProvider theme={theme}>
      {vm.step === ViewConnectCameraStep.NOT_CONNECTED_CAM ? renderNotConnectedContent : renderConnectionInfo}
    </ThemeProvider>
  );
}));
