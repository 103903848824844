import { add, format } from 'date-fns';
import { inject } from 'inversify';
import { action, computed, makeObservable, observable } from 'mobx';
import { NavigateFunction } from 'react-router';

import { AsyncTask } from '../../../domain/async/AsyncTask';
import { GroupSubscriptionModel } from '../../../domain/model/GroupSubscriptionModel';
import { GroupSubscriptionUserModel } from '../../../domain/model/GroupSubscriptionUserModel';
import { StripeInvoiceModel } from '../../../domain/model/stripe/StripeInvoiceModel';
import { I18nService } from '../../../domain/service/I18nService';
import { NotificationService } from '../../../domain/service/NotificationService';
import { StripeService } from '../../../domain/service/StripeService';
import { WsService } from '../../../domain/service/WsService';
import { SessionStore } from '../../../domain/store/SessionStore';
import { transient } from '../../../inversify/decorator';
import { Types } from '../../../inversify/types';
import { AppRoutes } from '../../../router/AppRoutesEnum';
import {
  GroupSubscriptionPutRequestDto
} from '../../../shared/dto/subscription/groupSubscription.put.request.dto';
import {
  STRIPE_SUBSCRIPTION_STATUS
} from '../../../shared/enum/stripe/stripeSubscriptionStatus.enum';
import { WsEvent } from '../../../shared/ws/ws.event';
import { GroupPricingVm } from '../GroupPricingVm';
import { GroupOverviewTypeEnum, IGroupOverviewMembersOnly } from '../types/GroupOverviewTypes';

@transient()
export class UpdateGroupSubscriptionVm extends GroupPricingVm {

  @observable
  public groupSubscriptionInfo: GroupSubscriptionModel = new GroupSubscriptionModel();

  /** This is for a NEXT invoice amount. Calculation is done by Stripe. */
  @observable
  public upcomingInvoice: StripeInvoiceModel = new StripeInvoiceModel();

  constructor(
    @inject(I18nService) protected override readonly i18n: I18nService,
    @inject(NotificationService) protected override readonly notification: NotificationService,
    @inject(StripeService) protected override readonly stripeService: StripeService,
    @inject(WsService) private readonly wsService: WsService,
    @inject(Types.Navigate) private readonly navigate: NavigateFunction,
    @inject(SessionStore) public override readonly sessionStore: SessionStore,
  ) {
    super(stripeService, i18n, notification, sessionStore);
    makeObservable(this);
  }

  public override async onInit() {
    await this.fetchData.run();

    this.wsService.registerHandler(WsEvent.UserStripeChargeSucceeded, this.rerouteUser);
    this.wsService.registerHandler(WsEvent.UserStripeChargePending, this.rerouteUser);
  }

  public override async onDestroy() {
    this.wsService.deregisterHandler(WsEvent.UserStripeChargeSucceeded, this.updateGroupSubscription.run);
    this.wsService.deregisterHandler(WsEvent.UserStripeChargePending, this.updateGroupSubscription.run);
  }

  public fetchData = new AsyncTask(async () => {
    await Promise.all([
      this.getProducts.run(),
      this.getRelatedMembers.run(),
      this.getSubscriptionInfo.run(),
    ]);

    if (!this.groupSubscriptionInfo.canceledAt) {
      await this.getUpcomingInvoice.run();
    }
  })

  /** When upgrading subscription is successful or SEPA payment is pending, re-route user to manage group screen */
  private rerouteUser = () => setTimeout(() => this.navigate(AppRoutes.ManageGroup), 3000);

  @action
  public setGroupSubscriptionInfo = (groupSubscriptionInfo: GroupSubscriptionModel) => {
    this.groupSubscriptionInfo = groupSubscriptionInfo;
  }

  @action
  public setUpcomingInvoice = (upcomingInvoice: StripeInvoiceModel) => {
    this.upcomingInvoice = upcomingInvoice;
  }

  @computed
  public get endDate(): string {
    if (this.groupSubscriptionInfo.expiresAt) {
      return format(new Date(this.groupSubscriptionInfo.expiresAt), 'dd.MM.yyyy');
    }

    return format(add(new Date(), { years: 1 }), 'dd.MM.yyyy');
  }

  @computed
  public get taxRate(): string {
    return `${this.upcomingInvoice.taxRate}%`;
  }

  /** Represents all newly assigned users in overview table */
  @computed.struct
  public get groupMembersOverview(): Set<GroupSubscriptionUserModel> {
    return new Set([...Array.from(this.assignedMembers)]);
  }

  /** Represents the number of pre-paid seats that are now assigned */
  @computed
  public get prepaidSeatsFilled(): number {
    return Math.min(this.groupSubscriptionInfo.emptySeats, this.groupMembersOverview.size);
  }

  /** Represents discount that should apply because there are some of unassigned seats in current group subscription  */
  @computed
  public get emptySeatDiscount(): number {
    return Number((this.priceForEveryNextUser * this.prepaidSeatsFilled).toFixed(2));
  }

  /** Number of assigned users when empty seats are fulfilled */
  @computed
  public get additionalAssignedUsers(): number {
    return Math.max(0, this.groupMembersOverview.size - this.prepaidSeatsFilled);
  }

  public override get assignedMembersPriceWithoutDiscount(): number {
    return Number((this.additionalAssignedUsers * this.priceForEveryNextUser).toFixed(2));
  }

  @computed
  public get fullProratedPrice(): number {
    return Number(this.upcomingInvoice.items.reduce((sum, item) => sum + item.amount, 0).toFixed(2));
  }

  /** Calculates prorated price for users that are not assigned to empty seats or that are not eligible for a discount (PRO users)  */
  @computed
  public get proratedPricePerUser(): number {
    return this.additionalAssignedUsers > 0 ? Number((this.fullProratedPrice / this.additionalAssignedUsers).toFixed(2)) : 0;
  }

  private calculateMemberDiscount(member: GroupSubscriptionUserModel, index: number): number {
    if (index < this.prepaidSeatsFilled) {
      return 0;
    }
    if (member.subscription && member.isYearlySubscription) {
      return this.priceForEveryNextUser;
    }
    return this.priceForEveryNextUser - this.proratedPricePerUser;
  }

  /**
   * Computes the total discount for assigned members, excluding those occupying empty seats.
   *
   * - Skips the first `prepaidSeatsFilled` members since they occupy pre-paid seats.
   * - For each remaining member:
   *   - Adds `priceForEveryNextUser` if the member has a yearly subscription.
   *   - Adds the prorated discount (`priceForEveryNextUser - proratedPricePerUser`) for non-yearly subscriptions.
   *
   * @returns {number} Total discount for the assigned members.
 */
  @computed
  public get assignedMembersDiscount(): number {
    return Number(Array.from(this.assignedMembers).reduce((acc, member, index) => {
      return acc + this.calculateMemberDiscount(member, index);
    }, 0).toFixed(2));
  }

  @computed
  public get assignedMembersFinalPrice(): number {
    return Number(Math.max(0, this.assignedMembersPriceWithoutDiscount - this.assignedMembersDiscount).toFixed(2));
  }

  /** Calculates the total price without discount for the updated group -> renewal price */
  @computed
  public get groupTotalPriceWithoutDiscount(): number {
    const existingQuantityPriceWithoutOwner = (this.groupSubscriptionInfo.quantity - 1) * this.priceForEveryNextUser;
    return Number((this.priceForOneUser + this.assignedMembersPriceWithoutDiscount + existingQuantityPriceWithoutOwner).toFixed(2));
  }

  /** Calculates the total discount for the updated group, excluding the owner */
  @computed
  public get groupTotalDiscount(): number {
    return Number(this.assignedMembersDiscount.toFixed(2));
  }

  /** Calculates the final total price after applying discounts, excluding the owner */
  @computed
  public get groupTotalPriceAfterDiscount(): number {
    return Number(this.assignedMembersFinalPrice.toFixed(2));
  }

  /**
   * Retrieves the starting balance from the upcoming invoice, which represents any credit the owner has from previous subscription adjustments (e.g., reducing subscription quantity).
   * This balance is automatically applied to offset the current or future invoices.
  */
  @computed
  public get startingBalance(): number {
    return Number(this.upcomingInvoice.startingBalance.toFixed(2));
  }

  /**
   * Calculates the adjusted balance the owner needs to pay after applying all discounts and any available starting balance (credit).
   * This is the total cost after factoring in discounts and credits.
   * If the adjusted balance is negative, it will reflect how much credit remains on the account.
  */
  @computed
  public get adjustedBalance(): number {
    return Number((this.groupTotalPriceAfterDiscount + this.startingBalance).toFixed(2));
  }

  /**
   * Calculates the final amount the group owner needs to pay after applying all discounts and starting balance (credit).
   * - If `adjustedBalance` is negative or zero, the final price is set to 0. This ensures that no negative balance is charged to the owner's credit card.
   * - Any negative amount remains as a credit for future use rather than being refunded.
  */
  @computed
  public get finalAmountDue(): number {
    return Number(Math.max(0, this.adjustedBalance).toFixed(2));
  }

  @computed.struct
  public get groupOverviewWithoutOwner(): IGroupOverviewMembersOnly {
    return {
      type: GroupOverviewTypeEnum.MembersOnly,
      groupMembersOverview: this.groupMembersOverview,
      assignedMembers: this.assignedMembers,
      candidatesForAssignment: this.candidatesForAssignment,
      groupTotalPriceWithoutDiscount: this.groupTotalPriceWithoutDiscount,
      groupTotalDiscount: this.groupTotalDiscount,
      groupTotalPriceAfterDiscount: this.groupTotalPriceAfterDiscount,
      currency: this.currency,
      priceForEveryNextUser: this.priceForEveryNextUser,
      groupSubscriptionInfo: this.groupSubscriptionInfo,
      proratedPricePerUser: this.proratedPricePerUser,
    };
  }

  @computed
  public get shouldRenderForwardingBlockerModal() {
    const { status } = this.groupSubscriptionInfo || {};
    return status
      && status !== STRIPE_SUBSCRIPTION_STATUS.ACTIVE
      && status !== STRIPE_SUBSCRIPTION_STATUS.TRIALING
      && status !== STRIPE_SUBSCRIPTION_STATUS.CANCELED
      && status !== STRIPE_SUBSCRIPTION_STATUS.INCOMPLETE_EXPIRED;
  }

  @computed.struct
  private get existingAndAssignedMembersIds(): string[] {
    const existingMembers = [this.groupSubscriptionInfo.owner, ...this.groupSubscriptionInfo.members];
    const newlyAssignedMembers = Array.from(this.assignedMembers);
    const allMemberIds = [...existingMembers, ...newlyAssignedMembers].map(member => member!.id);

    // Use a Set to remove duplicates and convert back to an array
    return Array.from(new Set(allMemberIds));
  }

  @computed
  public get canceledSubscriptionEmptySeats(): number {
    return this.groupSubscriptionInfo.canceledAt ? this.groupSubscriptionInfo.emptySeats : 0;
  }

  public getSubscriptionInfo = new AsyncTask(async () => {
    const response = await this.stripeService.getSubscriptionInfo();

    if (response) {
      return this.setGroupSubscriptionInfo(response);
    }
  })

  public getUpcomingInvoice = new AsyncTask(async (quantity?: number | undefined) => {
    const response = await this.stripeService.getUpcomingInvoice(quantity);

    if (response) {
      return this.setUpcomingInvoice(response);
    }
  });

  public updateGroupSubscription = new AsyncTask(async () => {
    const filteredMemberIds = this.existingAndAssignedMembersIds.filter(
      id => id && id !== this.groupSubscriptionInfo.owner?.id // Exclude owner's ID and undefined IDs
    );

    const dto: GroupSubscriptionPutRequestDto = this.groupSubscriptionInfo.toDto(
      filteredMemberIds,
      this.upcomingInvoice.coupon,
      Math.max(this.groupSubscriptionInfo.quantity, this.existingAndAssignedMembersIds.length),
      this.groupTotalDiscount,
    );

    const response = await this.stripeService.updateGroupSubscription(dto);
    if (response) {
      this.setGroupSubscriptionInfo(response);
      await this.delayNavigation();
    }
  });

  private delayNavigation = (): Promise<void> => {
    return new Promise((resolve) => {
      setTimeout(() => {
        if (this.groupSubscriptionInfo?.paymentResolveUrl && this.shouldRenderForwardingBlockerModal) {
          resolve();
          return;
        } else {
          this.notification.success(this.i18n.t('manage_group:success.updating_group_subscription'));
          this.navigate(AppRoutes.ManageGroup);
          resolve();
        }
      }, 3000);
    });
  };

}
