import { inject } from 'inversify';
import { action, computed, IReactionDisposer, makeObservable, observable, reaction } from 'mobx';

import { ViewModel } from '../../../../../domain/core/ViewModel';
import { DistrictModel } from '../../../../../domain/model/DistrictModel';
import { EntryModel } from '../../../../../domain/model/EntryModel';
import { MapPathModel } from '../../../../../domain/model/MapPathModel';
import { MapPathTypeModel } from '../../../../../domain/model/MapPathType';
import { PoiModel } from '../../../../../domain/model/PoiModel';
import { SubzoneModel } from '../../../../../domain/model/SubzoneModel';
import { TaskModel } from '../../../../../domain/model/TaskModel';
import { I18nService } from '../../../../../domain/service/I18nService';
import { SessionStore } from '../../../../../domain/store/SessionStore';
import { transient } from '../../../../../inversify/decorator';
import { DISTRICT_ROLE, FEATURE } from '../../../../../shared/enum';
import { IGeoLocation } from '../../../../../shared/interfaces/IGeoLocation';
import { DistrictsSelectorFilter } from '../../../filters/DistrictsSelectorFilter';
import { IPoiTab, PoiTabType } from '../../poi/new-poi/NewPoiVm';

export enum DistrictDetailsTabEnum {
  Members = 'district:tab_members',
  Tasks = 'district:tab_tasks',
  Pois = 'district:tab_pois',
  Entries = 'district:tab_entries',
  Paths = 'district:tab_paths',
  Zones = 'district:tab_zones',
  HarvestList = 'district:tab_harvest_list',
  CheckinList = 'district:tab_checkin_list',
  QuotaPlanning = 'district:tab_quota_planning'
}

export enum DistrictTab {
  Area = 'district:tab_area_menu',
  SelectArea = 'district:tab_select_area',
}

export interface IDistrictDetailsTab {
  name: DistrictDetailsTabEnum;
  enabled: boolean;
}

export interface IViewDistrictProps {
  selectedDistrict: DistrictModel;
  districtsSelectorFilter: DistrictsSelectorFilter;
  onDistrictLeft: (district: DistrictModel) => void;
  districts: DistrictModel[];
  pois: PoiModel[];
  tasks: TaskModel[];
  onPoiDetails: (poi: PoiModel) => void;
  onPoiDelete: (poi: PoiModel) => void;
  onPoiEdit: (poi: PoiModel, tab: IPoiTab) => void;
  entries: EntryModel[];
  onEntryEdit: (entry: EntryModel) => void;
  onEntryDelete: (entry: EntryModel) => void;
  onMapPathEdit: (path: MapPathModel) => void;
  onMapPathDelete: (path: MapPathModel) => void;
  paths: MapPathModel[];
  pathTypes: MapPathTypeModel[];
  subzones: SubzoneModel[];
  onSubzoneEdit: (subzone: SubzoneModel) => void;
  onSubzoneDelete: (subzone: SubzoneModel) => void;
  onClose: () => void;
  onSelectDistrict: (district: DistrictModel) => void;
  onEditDistrict: (district: DistrictModel, points?: IGeoLocation[]) => void;
  onDeleteDistrict: (district: DistrictModel) => void;
  onNewDistrict: (name: string, points: IGeoLocation[], pois: PoiModel[], kmlImport: boolean) => void;
  importMultiplePois?: (pois: PoiModel[]) => void;
}

@transient()
export class ViewDistrictVm extends ViewModel<IViewDistrictProps> {

  @observable
  public currentDetailsTab: IDistrictDetailsTab | null = null;

  @observable
  public currentTab: DistrictTab = DistrictTab.Area;

  @observable
  public regionSelection = false;

  private setCurrentDetailsTabReaction: IReactionDisposer | null = null;

  constructor(
    @inject(SessionStore) private readonly session: SessionStore,
    @inject(I18nService) private readonly i18n: I18nService,
  ) {
    super();
    makeObservable(this);
  }

  @action
  public setTab = (tab: DistrictTab) => {
    this.currentTab = tab;
  }

  @computed
  public get isOwnerOrAdmin() {
    return this.props.selectedDistrict.members.find(
      member => member.user?.id === this.session.userId && (member.role?.name === DISTRICT_ROLE.OWNER || member.role?.name === DISTRICT_ROLE.ADMIN)
    );
  }

  @action
  public override async onInit() {
    this.setCurrentDetailsTabReaction = reaction(
      () => this.props.selectedDistrict,
      () => {
        if (!this.currentDetailsTab) {
          this.currentDetailsTab = this.tabs[0];
        }

        if (this.currentDetailsTab?.name === DistrictDetailsTabEnum.Members && this.props.selectedDistrict.isWorldMap) {
          this.currentDetailsTab = this.tabs[0];
        }
      },
      { fireImmediately: true }
    );
  }

  @action
  public openRegionSelection = () => {
    this.regionSelection = true;
  }

  @action
  public closeRegionSelection = () => {
    this.regionSelection = false;
  }

  public override async onDestroy() {
    this.setCurrentDetailsTabReaction?.();
  }

  @computed
  public get role(): string {
    return this.i18n.t(this.props.selectedDistrict.roleI18nKey);
  }

  @computed
  public get tabs(): IDistrictDetailsTab[] {
    const tabs = [{
      name: DistrictDetailsTabEnum.Tasks,
      enabled: this.session.hasFeatureEnabled(FEATURE.TASKS),
    }, {
      name: DistrictDetailsTabEnum.Pois,
      enabled: true,
    }, {
      name: DistrictDetailsTabEnum.Entries,
      enabled: true,
    }, {
      name: DistrictDetailsTabEnum.Paths,
      enabled: this.session.hasFeatureEnabled(FEATURE.MAP_PATH),
    }];

    if (this.isOwnerOrAdmin) {
      tabs.push({
        name: DistrictDetailsTabEnum.QuotaPlanning,
        // enabled: this.session.hasFeatureEnabled(FEATURE.QUOTA_PLANNING),
        enabled: true,
      });
    }

    if (!this.props.selectedDistrict.isWorldMap) {
      tabs.unshift({
        name: DistrictDetailsTabEnum.Members,
        enabled: true,
      });

      tabs.push(
        {
          name: DistrictDetailsTabEnum.Zones,
          enabled: this.session.hasFeatureEnabled(FEATURE.SUBZONES),
        },
        {
          name: DistrictDetailsTabEnum.HarvestList,
          enabled: this.session.hasFeatureEnabled(FEATURE.HARVEST_TAG_EXPORT),
        },
        {
          name: DistrictDetailsTabEnum.CheckinList,
          enabled: this.session.hasFeatureEnabled(FEATURE.CHECKIN_TAG_EXPORT),
        },
      );
    }

    return tabs;
  }

  @action
  public setSelectedDistrictTab = (tab: IDistrictDetailsTab) => {
    this.currentDetailsTab = tab;
  }

  @action
  public startNewDistrict = (name: string, points: IGeoLocation[], pois: PoiModel[], kmlImport: boolean) => {
    this.props.onNewDistrict(name, points, pois, kmlImport);
    this.props.onClose();
  }

  @action
  public onPoiEdit = (poi: PoiModel) => {
    this.props.onPoiEdit(poi, { type: PoiTabType.DETAILS, enabled: true });
  }
}
