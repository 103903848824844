import { inject } from 'inversify';
import { action, computed, makeObservable, observable } from 'mobx';

import { AsyncTask } from '../../domain/async/AsyncTask';
import { AreaPostRequestModel } from '../../domain/model/AreaPostRequestModel';
import { DistrictModel } from '../../domain/model/DistrictModel';
import { PoiModel } from '../../domain/model/PoiModel';
import { TaskModel } from '../../domain/model/TaskModel';
import { PoiProxy } from '../../domain/proxy/PoiProxy';
import { TaskProxy } from '../../domain/proxy/TaskProxy';
import { I18nService } from '../../domain/service/I18nService';
import { NotificationService } from '../../domain/service/NotificationService';
import { TrackingEvent } from '../../domain/service/tracking/TrackingEvent';
import { TrackingService } from '../../domain/service/tracking/TrackingService';
import { SessionStore } from '../../domain/store/SessionStore';
import { transient } from '../../inversify/decorator';
import { POI_TYPE } from '../../shared/enum';
import { IGeoLocation } from '../../shared/interfaces/IGeoLocation';
import { IMapBounds } from './components/google-map/GoogleMapVm';
import { IPoiTab, PoiTabType } from './components/poi/new-poi/NewPoiVm';

@transient()
export class MapPoiVm {

  // * for displaying on a map in a current bounds
  @observable
  public poisInMapBounds: PoiModel[] = [];

  // * for district view sidebar
  @observable
  public selectedDistrictPois: PoiModel[] = [];

  @observable
  public tasks: TaskModel[] = [];

  @observable
  public currentPoi: PoiModel | null = null;

  @observable
  public viewMode = false;

  @observable
  public newPoiTab: IPoiTab = {
    type: PoiTabType.POSITION,
    enabled: true,
  }

  @observable
  public poisImportInProgress: boolean = false;

  constructor(
    @inject(PoiProxy) private readonly poiProxy: PoiProxy,
    @inject(TaskProxy) private readonly taskProxy: TaskProxy,
    @inject(SessionStore) private readonly session: SessionStore,
    @inject(NotificationService) private readonly notification: NotificationService,
    @inject(I18nService) private readonly i18n: I18nService,
    @inject(TrackingService) private readonly tracking: TrackingService,
  ) {
    makeObservable(this);
  }

  @action
  public setPoisImportInProgress = (importInProgress: boolean) => {
    this.poisImportInProgress = importInProgress;
  }

  @computed
  public get pois() {
    if (this.currentPoi) {
      return this.poisInMapBounds
        .filter((p) => p.id !== this.currentPoi?.id)
        .concat(this.currentPoi);
    }

    return this.poisInMapBounds;
  }

  @computed
  public get weatherPoisCount() {
    return this.pois.filter((p) => p.type === POI_TYPE.WEATHER).length;
  }

  @action
  public setViewMode = (showing: boolean) => {
    this.viewMode = showing;
  }

  @action
  public setCurrentTab = (tab: IPoiTab) => {
    this.newPoiTab = tab;
  }

  @action
  public startNewPoi = async () => {
    await this.tracking.track(TrackingEvent.POI_CREATION_STARTED);

    this.setCurrentPoi(new PoiModel());
    this.setCurrentTab({ type: PoiTabType.POSITION, enabled: true, });
    this.setViewMode(false);
  }

  @action
  public startPoiEdit = (poi: PoiModel, tab: IPoiTab) => {
    this.setCurrentPoi(poi.clone());
    this.setCurrentTab(tab);
  }

  @action
  public showPoi = (poi: PoiModel) => {
    this.setCurrentPoi(poi);
    this.setViewMode(true);
  }

  @action
  public closePoi = () => {
    this.setCurrentPoi(null);
    this.setViewMode(false);
  }

  @action
  private setCurrentPoi = (poi: PoiModel | null) => {
    this.currentPoi = poi;
  }

  @action
  public mapClick = (location: IGeoLocation, selectedDistrict: DistrictModel | null) => {
    if (!this.currentPoi) {
      return;
    }

    if (this.newPoiTab.type !== PoiTabType.POSITION) {
      return;
    }

    if (this.viewMode) {
      return;
    }

    this.currentPoi.districtId = selectedDistrict?.realId;
    this.currentPoi.setLocation(location, 'map');
  }

  @action
  public deletePoiId = (id: string) => {
    this.poisInMapBounds = this.poisInMapBounds.filter((p) => p.id !== id);
    this.selectedDistrictPois = this.selectedDistrictPois.filter((p) => p.id !== id);
  }

  @action
  public deleteTaskId = (id: string) => {
    this.tasks = this.tasks.filter((p) => p.id !== id);
  }

  @action
  public setPoisInMapBounds = (pois: PoiModel[]) => {
    this.poisInMapBounds = pois;
  }

  @action
  public setSelectedDistrictPois = (pois: PoiModel[]) => {
    // * remove duplicates based on the 'id' property
    this.selectedDistrictPois = Array.from(new Map(pois.map(poi => [poi.id, poi])).values());
  }

  @action
  public setTasks = (tasks: TaskModel[]) => {
    this.tasks = tasks;
  }

  @action
  public upsert = (poi: PoiModel) => {
    this.updateArrayPoi(this.poisInMapBounds, poi);
    this.updateArrayPoi(this.selectedDistrictPois, poi);
    this.refreshCurrentPoi(poi);
  }

  @action
  public upsertTask = (task: TaskModel) => {
    const index = this.tasks.findIndex((p) => p.id === task.id);

    if (index === -1) {
      this.tasks.unshift(task);
    } else {
      this.tasks.splice(index, 1, task);
    }
  }

  private updateArrayPoi = (array: PoiModel[], poi: PoiModel) => {
    const index = array.findIndex((el: PoiModel) => el.id === poi.id);

    if (index === -1) {
      array.unshift(poi);
    } else {
      array.splice(index, 1, poi);
    }
  }

  @action
  public cancelPoisImport = () => {
    this.setPoisImportInProgress(false);
  }

  // * Fetch points of interest (POIs) within the specified map bounds, disregarding district boundaries.
  public getPoisInMapBounds = new AsyncTask(async (mapBounds: IMapBounds) => {
    try {
      if (this.poisImportInProgress) {
        return;
      }

      this.setPoisInMapBounds([]);

      if (!this.session.isProUser) {
        return;
      }
      const dto = new AreaPostRequestModel().toDto(mapBounds);

      const result = await this.poiProxy.getPoisInMapBounds(dto);
      if (result.ok) {
        return this.setPoisInMapBounds(result.data);
      }

      console.warn(`error while loading POIs. ${result.status}`);
      this.notification.warning(this.i18n.t('map:pois_loading_error'));
    } catch (e) {
      console.error(`exception while loading POIs. ${e}`);
      this.notification.error(this.i18n.t('map:pois_loading_error'));
    }
  })

  public getDistrictPois = new AsyncTask(async (district: DistrictModel) => {
    try {
      this.setSelectedDistrictPois([]);

      if (!this.session.isProUser) {
        return;
      }

      if (district.isUnsaved) {
        return;
      }

      const result = await this.poiProxy.getDistrictPois.run(district.id);
      if (result.ok) {
        return this.setSelectedDistrictPois(result.data);
      }

      console.warn(`error while loading district POIs. ${result.status}`);
      this.notification.warning(this.i18n.t('map:pois_loading_error'));
    } catch (e) {
      console.error(`exception while loading district POIs. ${e}`);
      this.notification.error(this.i18n.t('map:pois_loading_error'));
    }
  })

  public save = async (): Promise<boolean> => {
    try {
      if (!this.currentPoi) {
        return false;
      }

      const result = this.currentPoi.id
        ? await this.poiProxy.updatePoi(this.currentPoi.toPutDto())
        : await this.poiProxy.createPoi(this.currentPoi.toPostDto());

      if (result.ok) {
        this.notification.success(this.i18n.t('poi:poi_saved'));
        await this.tracking.track(this.currentPoi.id ? TrackingEvent.POI_UPDATE_COMPLETED : TrackingEvent.POI_CREATION_COMPLETED);

        this.upsert(result.data);
        this.setCurrentPoi(null);

        return true;
      }

      this.notification.error(this.i18n.t('poi:poi_save_error'));
      return false;
    } catch (e) {
      console.error(`error while updating user. ${e}`);
      this.notification.error(this.i18n.t('poi:poi_save_error'));
      return false;
    }
  }

  public saveMultiplePois = new AsyncTask(async (pois: PoiModel[]) => {
    try {
      const poisPostRequestDto = pois.map(poi => poi.toPostDto());
      const poiSaveResponse = await this.poiProxy.createMultiplePois(poisPostRequestDto);

      this.setPoisImportInProgress(false);

      if (poiSaveResponse.ok) {
        return this.notification.success(this.i18n.t('district:pois_import_success'));
      }

      return this.notification.error(this.i18n.t('district:pois_import_error'));
    } catch (error) {
      console.error('Something went wrong with saving multiple pois: ', error);
      return this.notification.error(this.i18n.t('district:pois_import_error'));
    }
  })

  public deletePoi = new AsyncTask(async (poi: PoiModel): Promise<void> => {
    try {
      const response = await this.poiProxy.deletePoi(poi);

      if (response.ok && response.status === 204) {
        this.deletePoiId(poi.id);
        return this.notification.success(this.i18n.t('poi:delete.success'));
      }
      this.notification.error(this.i18n.t('poi:delete.error'));

    } catch (e) {
      console.error(`error while handling poi delete. ${e}`);
      this.notification.error(this.i18n.t('poi:delete.error'));
    }
  });

  public getDistrictTasks = new AsyncTask(async (district: DistrictModel) => {
    try {
      if (district.isUnsaved) {
        return;
      }

      if (this.session.isProUser) {
        const result = await this.taskProxy.getDistrictTasks(district.id);
        if (result.ok) {
          return this.setTasks(result.data);
        }
        console.warn(`error while loading tasks. ${result.status}`);
        this.notification.warning(this.i18n.t('map:tasks_loading_error'));
      }
    } catch (e) {
      console.error(`exception while loading map paths. ${e}`);
      this.notification.error(this.i18n.t('map:tasks_loading_error'));
    }
  });

  @action
  public refreshCurrentPoi = (poi: PoiModel) => {
    if (this.currentPoi && this.currentPoi.id === poi.id) {
      this.setCurrentPoi(poi);
    }
  }
}
