import { inject } from 'inversify';
import { action, computed, makeObservable, observable } from 'mobx';

import { AsyncTask } from '../../../../../../../domain/async/AsyncTask';
import { ViewModel } from '../../../../../../../domain/core/ViewModel';
import { DistrictModel } from '../../../../../../../domain/model/DistrictModel';
import { EntryModel } from '../../../../../../../domain/model/EntryModel';
import { I18nService } from '../../../../../../../domain/service/I18nService';
import { NotificationService } from '../../../../../../../domain/service/NotificationService';
import { SessionStore } from '../../../../../../../domain/store/SessionStore';
import { env } from '../../../../../../../env';
import { transient } from '../../../../../../../inversify/decorator';
import { WORLD_MAP_ID } from '../../../../../../../shared/deep-linking/DeepLink.constants';
import { EntryHelper } from '../../../../../../../util/EntryHelper';
import { FileDownloadHelper } from '../../../../../../../util/FileDownloaderHelper';

export interface IViewDistrictEntriesProps {
  entries: EntryModel[];
  onEdit: (entry: EntryModel) => void;
  onDelete: (entry: EntryModel) => void;
  selectedDistrict: DistrictModel | null;
  districts: DistrictModel[];
}

export type TabKeyType = 'all' | 'own';

interface IEntryTab {
  key: TabKeyType;
  count: number;
  text: string;
}

@transient()
export class ViewDistrictEntriesVm extends ViewModel<IViewDistrictEntriesProps> {

  @observable
  public activeTab: TabKeyType = 'all';

  constructor(
    @inject(I18nService) private readonly i18n: I18nService,
    @inject(SessionStore) private readonly session: SessionStore,
    @inject(EntryHelper) private readonly entryHelper: EntryHelper,
    @inject(NotificationService) private readonly notification: NotificationService,
  ) {
    super();
    makeObservable(this);
  }

  @computed
  public get tabs(): IEntryTab[] {
    const allCount = this.props.entries.length;
    const ownCount = this.props.entries.filter((e) => e.owner?.id === this.session.userId).length;

    return [
      { key: 'all', count: allCount, text: this.i18n.t('district:district_details_show_all_items') },
      { key: 'own', count: ownCount, text: this.i18n.t('entry:own_entries') },
    ];
  }

  /** Computed property to filter entries based on the active tab.  */
  @computed
  public get entries(): EntryModel[] {
    return this.activeTab === 'all'
      ? this.props.entries
      : this.props.entries.filter((e) => e.owner?.id === this.session.userId);
  }

  @action
  public changeTab = (e: unknown, tab: TabKeyType) => {
    this.activeTab = tab;
  }

  public canEditOrDeleteEntry = (entry: EntryModel) => {
    return this.entryHelper.canEditAndDeleteEntries(
      entry,
      this.props.selectedDistrict,
      this.props.districts,
    );
  }

  public exportHarvestsAndSightings = new AsyncTask(async () => {
    try {
      const sessionToken = this.session.session?.sessionToken;
      const districtId = this.props.selectedDistrict?.id;
      const userId = this.session.userId;

      if (!sessionToken) {
        throw new Error('Session token is missing.');
      }

      if (!districtId) {
        throw new Error('Selected district ID is missing.');
      }

      const queryParams = new URLSearchParams({
        token: sessionToken,
        ...(districtId !== WORLD_MAP_ID && { districtId: districtId }),
        ...(this.activeTab === 'own' && { userId: userId }),
      });

      const url = `${env.api}/data-export/entries?${queryParams.toString()}`;
      await FileDownloadHelper.fetchAndDownloadFile(url, sessionToken, 'harvests_and_sightings_export.xlsx');

      this.notification.success(this.i18n.t('district:export_entries_success'));
    } catch (error) {
      console.error('Failed to export harvests and sightings:', error);
      this.notification.error(this.i18n.t('district:export_entries_error'));
    }
  });

}
