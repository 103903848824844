export enum TrackingEvent {
  REGISTRATION_STARTED = 'registration_started',
  REGISTRATION_COMPLETED = 'registration_completed',
  LOGIN_STARTED = 'login_started',
  LOGIN_COMPLETED = 'login_completed',
  PROFILE_USER_VIEW = 'profile_user_view',
  DOWNLOAD_PAGE_VIEW = 'download_page_view',
  PRICING_PAGE_VIEW = 'pricing_page_view',
  PRO_FREE_COMPARISON_VIEW = 'pro_free_comparison_view',
  TERMS_VIEW = 'terms_view',
  MANAGE_SUBSCRIPTION = 'manage_subscription',
  SUBSCRIPTION_STARTED = 'subscription_started',
  SUBSCRIPTION_COMPLETED = 'subscription_completed',
  GROUPSUBSCRIPTIONPRICING_PAGE_VIEW = 'groupsubscriptionpricing_page_view',
  GROUPSUBSCRIPTION_STARTED = 'groupsubscription_started',
  AMOUNT_CHANGED = 'amount_changed',
  PRINT_MAP_VIEW = 'print_map_view',
  PRINT_MAP_STARTED = 'print_map_started',
  FREE_PRINT_MAP_STARTED = 'free_print_map_started',
  DISTRICT_CREATION_STARTED = 'district_creation_started',
  DISTRICT_UPDATE_STARTED = 'district_update_started',
  DISTRICT_CREATION_COMPLETED = 'district_creation_completed',
  DISTRICT_UPDATE_COMPLETED = 'district_update_completed',
  DISTRICT_IMPORTED = 'district_imported',
  DISTRICT_JOINED = 'district_joined',
  HARVEST_CREATION_STARTED = 'harvest_creation_started',
  HARVEST_CREATION_COMPLETED = 'harvest_creation_completed',
  HARVEST_UPDATE_COMPLETED = 'harvest_update_completed',
  SIGHTING_CREATION_STARTED = 'sighting_creation_started',
  SIGHTING_CREATION_COMPLETED = 'sighting_creation_completed',
  SIGHTING_UPDATE_COMPLETED = 'sighting_update_completed',
  MISC_CREATION_STARTED = 'misc_creation_started',
  MISC_CREATION_COMPLETED = 'misc_creation_completed',
  MISC_UPDATE_COMPLETED = 'misc_update_completed',
  POI_CREATION_STARTED = 'poi_creation_started',
  POI_CREATION_COMPLETED = 'poi_creation_completed',
  POI_UPDATE_COMPLETED = 'poi_update_completed',
  TRAIL_CREATION_STARTED = 'trail_creation_started',
  TRAIL_CREATION_COMPLETED = 'trail_creation_completed',
  FREE_TRAIL_VIEW = 'free_trail_view',
  ZONE_CREATION_STARTED = 'zone_creation_started',
  ZONE_CREATION_COMPLETED = 'zone`_creation_completed',
  FILTER_OPENED = 'filter_opened',
  FILTER_ACTIVATED = 'filter_activated',
  LABELS_ACTIVATED = 'labels_activated',
  GROUP_SUBSCRIPTION_PRICE = 'group_subscription_price',
  PROFILE_MULTIPLE_SUBSCRIPTIONS_CANCEL = 'profile_multiple_subscriptions_cancel',
  QUOTA_STARTED = 'quota_started',
  QUOTA_COMPLETED = 'quota_completed',
  QUOTA_ANIMAL_ADDED = 'quota_animal_added',
  QUOTA_EDIT = 'quota_edit',
  QUOTA_TOGGLE_ACTIVE = 'quota_toggle_active',
  QUOTA_TOGGLE_DEACTIVATED = 'quota_toggle_deactivated',
  MEMBER_CODE_REFRESHED = 'member_code_refreshed',
  MEMBER_CODE_COPIED = 'member_code_copied',
  MEMBER_TUTORIAL_CLICKED = 'member_tutorial_clicked',
  MEMBER_SWITCHER_CHANGED = 'member_switcher_changed',
  TRAILCAM_CONNECT_SELECTED = 'trailcam_connect_selected',
  TRAILCAM_COPYTOCLIPBOARD_SELECTED = 'trailcam_copytoclipboard_selected',
  TRAILCAM_CONNECTED = 'trailcam_connected',
}
