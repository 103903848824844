import { endOfDay, isEqual, startOfDay } from 'date-fns';
import { inject } from 'inversify';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { NavigateFunction } from 'react-router';

import { AsyncTask } from '../../../../../../../domain/async/AsyncTask';
import { ViewModel } from '../../../../../../../domain/core/ViewModel';
import { DistrictProxy } from '../../../../../../../domain/proxy/DistrictProxy';
import { I18nService } from '../../../../../../../domain/service/I18nService';
import { NotificationService } from '../../../../../../../domain/service/NotificationService';
import { SessionStore } from '../../../../../../../domain/store/SessionStore';
import { env } from '../../../../../../../env';
import { transient } from '../../../../../../../inversify/decorator';
import { Types } from '../../../../../../../inversify/types';
import { AppRoutes } from '../../../../../../../router/AppRoutesEnum';
import { FEATURE } from '../../../../../../../shared/enum';
import { FileDownloadHelper } from '../../../../../../../util/FileDownloaderHelper';

export interface ViewDistrictCheckinListProps {
  districtId: string;
}

@transient()
export class ViewDistrictCheckinListVm extends ViewModel<ViewDistrictCheckinListProps> {

  public currentDate = new Date();

  @observable
  public numberOfCheckins: number = 0;

  @observable
  public from: Date = this.defaultStartDate;

  @observable
  public to: Date = this.defaultEndDate;

  constructor(
    @inject(I18nService) public readonly i18n: I18nService,
    @inject(NotificationService) private readonly notification: NotificationService,
    @inject(SessionStore) private readonly session: SessionStore,
    @inject(DistrictProxy) private readonly districtProxy: DistrictProxy,
    @inject(Types.Navigate) private readonly navigate: NavigateFunction,
  ) {
    super();
    makeObservable(this);
  }

  public override onInit = async () => {
    await this.getCheckinCount.run();
  }

  @computed
  public get hasDateChanged() {
    return !isEqual(this.from, this.defaultStartDate) || !isEqual(this.to, this.defaultEndDate);
  }

  public get defaultStartDate(): Date {
    const now = new Date(this.currentDate);

    // Subtract 2 days from the current date
    now.setDate(now.getDate() - 2);

    return now;
  }

  public get defaultEndDate(): Date {
    return endOfDay(new Date(this.currentDate));
  }

  @computed
  public get hasProCheckinList() {
    return this.session.hasFeatureEnabled(FEATURE.CHECKIN_TAG_EXPORT);
  }

  @computed
  public get timezone(): string {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  @action
  public resetDates = () => {
    this.from = this.defaultStartDate;
    this.to = this.defaultEndDate;
    this.getCheckinCount.run();
  }

  @action
  public setFrom = (from: Date | null) => {
    if (!from) {
      return;
    }

    this.from = startOfDay(from);
    this.getCheckinCount.run();
  }

  @action
  public setTo = (to: Date | null) => {
    if (!to) {
      return;
    }

    this.to = endOfDay(to);
    this.getCheckinCount.run();
  }

  public getCheckinCount = new AsyncTask(async () => {
    try {
      const result = await this.districtProxy.getCheckinsCount(
        this.props.districtId,
        this.session.session!.sessionToken,
        this.from.toISOString(),
        this.to.toISOString(),
        this.timezone,
      );

      if (result.ok) {
        return runInAction(() => {
          this.numberOfCheckins = result.data.count;
        });
      }

      this.notification.error(this.i18n.t('district:harvest_filter_fetching_error'));
    } catch (error) {
      console.error('Something went wrong while trying to get number of checkins: ', error);
      return this.notification.error(this.i18n.t('check_ins:error.get_checkins_count'));
    }
  })

  private downloadCheckinHistory = new AsyncTask(async () => {
    try {
      const sessionToken = this.session.session?.sessionToken;
      const districtId = this.props.districtId;

      if (!sessionToken) {
        throw new Error('Session token is missing.');
      }

      if (!districtId) {
        throw new Error('Selected district ID is missing.');
      }

      const queryParams = new URLSearchParams({
        token: sessionToken,
        dateFrom: this.from.toISOString(),
        dateTo: this.to.toISOString(),
        timezone: this.timezone,
      });

      const url = `${env.api}/checkin-list/download/district/${districtId}?${queryParams.toString()}`;

      await FileDownloadHelper.fetchAndDownloadFile(url, sessionToken, 'checkin_history_export.xlsx');

      this.notification.success(this.i18n.t('check_ins:success.download_checkin_history'));
    } catch (error) {
      console.error('Failed to download checkin history:', error);
      this.notification.error(this.i18n.t('check_ins:error.download_checkin_history'));
    }
  });


  private showProPage = () => {
    this.navigate(AppRoutes.Pricing);
  }

  public onDownloadClick = () => {
    if (this.hasProCheckinList) {
      return this.downloadCheckinHistory.run();
    }

    this.showProPage();
  }

}
