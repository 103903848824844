import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {
  IconButton, styled, ThemeProvider, Tooltip, tooltipClasses, TooltipProps, Typography
} from '@mui/material';

import { useContainer } from '../../domain/hooks/useContainer';
import { TrackingEvent } from '../../domain/service/tracking/TrackingEvent';
import { TrackingService } from '../../domain/service/tracking/TrackingService';
import { useThemeContext } from '../../theme/ThemeContextProvider';

interface ICopyToClipboardProps {
  tooltipText: string;
  textCopied?: () => void;
  iconSize?: number;
  trackingEvent?: TrackingEvent;
}

const CopyClipboardTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.background.surface_80,
    minHeight: 25,
    border: `1px solid ${theme.palette.borderDividers?.border}`,
  },
}));

export const CopyToClipboard = function CopyToClipboard({ tooltipText, textCopied, iconSize, trackingEvent }: ICopyToClipboardProps) {
  const { t } = useTranslation();
  const { theme } = useThemeContext();
  const tracking = useContainer().get(TrackingService);

  const [checkmarkIcon, setCheckmarkIcon] = useState(false);
  const [tooltipMessage, setTooltipMessage] = useState(t('copy_clipboard:copy_to_clipboard'));

  const handleCopyToClipboard = useCallback(() => {
    textCopied && textCopied();
    setCheckmarkIcon(true);
    navigator.clipboard.writeText(tooltipText);
    setTooltipMessage(`${t('copy_clipboard:copied')} ${tooltipText}`);
    trackingEvent && tracking.track(trackingEvent);
    setTimeout(() => setCheckmarkIcon(false), 1000);
  }, [t, textCopied, tooltipText, trackingEvent, tracking]);

  return (
    <ThemeProvider theme={theme}>
      <CopyClipboardTooltip
        title={<Typography paddingX={1} variant='text_sm_regular' color={theme.palette.text.primary}>{tooltipMessage}</Typography>}
        placement='top'
        onClose={() => {
          setTimeout(() => {
            setTooltipMessage(t('copy_clipboard:copy_to_clipboard'));
          }, 1000);
        }}
      >
        <IconButton onClick={handleCopyToClipboard}>
          {checkmarkIcon ? (
            <CheckCircleOutlineIcon sx={{ width: iconSize, height: iconSize }} />
          ) : (
            <ContentCopyIcon sx={{ width: iconSize, height: iconSize }} />
          )}
        </IconButton>
      </CopyClipboardTooltip>
    </ThemeProvider>
  );

};
