import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';

import { Grid, TableCell, Typography, useMediaQuery } from '@mui/material';

import { GroupSubscriptionUserModel } from '../../../../../domain/model/GroupSubscriptionUserModel';
import { AppRoutes } from '../../../../../router/AppRoutesEnum';
import { MEMBER_SUBSCRIPTION_STATUS } from '../../../../../shared/enum';
import { useThemeContext } from '../../../../../theme/ThemeContextProvider';
import { AssignEmptySeat } from '../../../manage-group/components/AssignEmptySeat';

interface ISeatCellProps {
  index: number;
  row: GroupSubscriptionUserModel;
  isGroupOwner: boolean;
}

export const SeatCell: React.FC<ISeatCellProps> = function SeatCell({ index, row, isGroupOwner }: ISeatCellProps) {
  const { t } = useTranslation();
  const { theme } = useThemeContext();
  const isMobileSize = useMediaQuery(theme.breakpoints.down('desktop'));
  const location = useLocation();

  const isUnassignedSeat = row.id === '';
  const seatLabel = `${t('group_subscription:table.table_row.seat.seat')} ${index + 1} - `;
  const displayText = isGroupOwner ? t('group_subscription:table.table_row.seat.you') : row.displayName;

  const showAssignEmptySeat = location.pathname === AppRoutes.ManageGroup && isUnassignedSeat;

  const textVariant = React.useMemo(() => {
    return isUnassignedSeat
      ? (isMobileSize ? 'text_md_regular' : 'text_sm_regular')
      : (isMobileSize ? 'text_md_semibold' : 'text_sm_semibold');
  }, [isUnassignedSeat, isMobileSize]);

  const textColor = React.useMemo(() => {
    return isUnassignedSeat ? theme.palette.text.secondary : theme.palette.text.primary;
  }, [isUnassignedSeat, theme.palette.text]);

  return (
    <TableCell component="th" scope="row" padding="none" sx={{ paddingLeft: 2 }}>
      <Grid container direction='row' justifyContent={'flex-start'} gap={1} alignItems={'center'} width={'100%'}>
        <Grid item alignItems={'center'}>
          <Typography variant={isMobileSize ? 'text_md_regular' : 'text_sm_regular'} color={theme.palette.text.secondary}>
            {seatLabel}
          </Typography>
          <Typography variant={textVariant} color={textColor}>
            {displayText}
          </Typography>
          {(row.status && row.status === MEMBER_SUBSCRIPTION_STATUS.INVITATION_PENDING) &&
            <Typography variant={isMobileSize ? 'text_md_regular' : 'text_sm_regular'} color={theme.palette.text.secondary}>
              &nbsp;{t('group_subscription:table.table_row.seat.invitation_sent')}
            </Typography>
          }
        </Grid>
        {showAssignEmptySeat && (
          <Grid item>
            <AssignEmptySeat />
          </Grid>
        )}
      </Grid>
    </TableCell>
  );
};
